import type { SampledError } from '@main/api/errors/ApiError';

/**
 * Error that indicates that the API version and
 * the current client version are out of sync.
 */
export default class ApiVersionError extends Error implements SampledError {
    constructor( serverVersion: string, clientVersion: string ) {
        super(
            'API server version ' +
                serverVersion +
                ' does not match current client version ' +
                clientVersion +
                '.',
        );
        this.name = 'ApiVersionError';
    }

    public getSamplingRate(): number {
        return 0.001;
    }
}
