import {
    differenceInCalendarDays,
    format,
    formatRelative,
    intlFormat,
    isBefore,
    isToday,
    isYesterday,
    sub,
} from 'date-fns';

/**
 * The default Matchory color scale.
 */
export const defaultColorScale: string[] = [
    '#2196f3',
    '#ff9800',
    '#4caf50',
    '#f44336',
    '#9c27b0',
    '#795548',
    '#e91e63',
    '#9e9e9e',
    '#cddc39',
    '#00bcd4',
];

/**
 * Formats a percentage value for display.
 *
 * @param value          Numeric value between 0 and 100 to format.
 * @param fractionDigits Optional number of fraction digits to display. If 0,
 *                       no fraction digits will be shown.
 */
export function formatPercentage( value: number | string, fractionDigits?: number ) {
    return ( ( value as number ) / 100 ).toLocaleString( undefined, {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits && fractionDigits > 0 ? 1 : 0,
        style: 'percent',
    } );
}

/**
 * Formats an integer for display.
 *
 * @param value
 */
export function formatInteger( value: number | string ): string {
    const rounded = Math.round( value as number );

    return isNaN( rounded ) ? '' : rounded.toLocaleString();
}

export function upperCaseFirst( string: string ): string {
    return string.charAt( 0 ).toUpperCase() + string.slice( 1 );
}

export function upperCaseWords( string: string ): string {
    if ( !string ) {
        return '';
    }

    return string
        .split( /\s+/ )
        .map( ( word ) => upperCaseFirst( word ) )
        .join( ' ' );
}

export function placeholderLetters( string: string ): string {
    if ( !string ) {
        return '?';
    }

    string = string.trim();

    const parts = withoutSpecialChars( string )
        .split( /\s+/ )
        .filter( ( part ) => !!part );

    if ( parts.length === 0 ) {
        return '?';
    } else if ( parts.length === 1 ) {
        return ( parts[0][0] + parts[0][1] ).toUpperCase();
    } else if ( parts.length === 2 ) {
        return ( parts[0][0] + parts[1][0] ).toUpperCase();
    } else {
        return ( parts[0][0] + parts[1][0] + parts[2][0] ).toUpperCase();
    }
}

export function camelCaseToWords( string: string ): string {
    const result = string.replace( /([A-Z])/g, ' $1' );

    return result.charAt( 0 ).toUpperCase() + result.slice( 1 );
}

/**
 * Truncates a string to a maximum length.
 *
 * @param text
 * @param max
 */
export function truncate( text: string, max: number ): string {
    return text && text.length > max
        ? text.slice( 0, max ).split( ' ' ).slice( 0, -1 ).join( ' ' ) + '…'
        : text;
}

export function dateAsDiff( timestamp: string, includeTime = true ): string {
    const date = new Date( timestamp );
    const sevenDaysAgo = sub( Date.now(), { days: 7 } );
    const today = Date.now();

    if ( isToday( date ) || isYesterday( date ) ) {
        // today / yesterday at 11:55
        return includeTime
            ? formatRelative( date, today )
            : formatRelative( date, today ).split( ' at' )[0];
    }

    if ( isBefore( date, sevenDaysAgo ) ) {
        // Friday, 13. October 2021
        return intlFormat( date, {
            day: 'numeric',
            month: 'long',
            weekday: 'long',
            year: 'numeric',
        } );
    }

    // Friday, 4 days ago
    return (
        format( date, 'EEEE' ) + ', ' + Math.abs( differenceInCalendarDays( date, today ) ) + ' days ago'
    );
}

export function withoutSpecialChars( text: string ): string {
    return text.replace( /[&/\\#,+()$~%.'":*?<>{}-]/g, ' ' ).replace( /\s\s+/g, ' ' );
}

export function hashFromString( uuid: string ) {
    let hash = 0;

    for ( let i = 0; i < uuid.length; i++ ) {
        hash = ( hash << 5 ) - hash + uuid.charCodeAt( i );
        hash |= 0;
    }

    return Math.abs( hash );
}
