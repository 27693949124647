<script lang="ts" setup>
import { useHead } from '@unhead/vue';

useHead( {
    titleTemplate: ( title ) => ( title ? `${title} | Matchory` : 'Matchory: Supplier Discovery' ),
} );
</script>

<template>
    <v-app class="app-viewport">
        <router-view />
    </v-app>
</template>

<style lang="scss">
@import 'node_modules/vuetify/src/styles/styles';

html {
    // Fix always scrollbar shown.
    overflow-y: auto;
    // Modern scrollbar style
    scrollbar-color: map-get($grey, 'lighten-2') map-get($grey, 'base');
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background-color: map-get($grey, 'lighten-2');
    box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
    background-color: map-get($grey, 'base');
    border-radius: 0.5rem;
    box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.1);
}
</style>
