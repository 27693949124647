import { context } from '@main/api';

export type ApiQueryAspect = {
    // core aspect properties
    id: string;
    name: string;
    description: string;
    isAugmented: boolean;
    isRankFeature: boolean;
    version: number;

    // Default boost value
    defaultBoost: number;
};

export type ApiConditionBoost = {
    id: string;
    name: string;
    description: string;
};

export type ApiDebugSearchConfiguration = {
    readonly productionAspects: ApiQueryAspect[];
    readonly experimentalAspects: ApiQueryAspect[];
    readonly aspectBoosts: number[];
    readonly conditionBoosts: ApiConditionBoost[];
};

type QueryAspectAlias = string;

export type DebugFetchResults = {
    // Debug data
    includeDebugData: 0 | 1;
} & Partial<Record<QueryAspectAlias, 0 | 1>>;

export type ApiIndexHistoryItem = {
    date: string;
    nickname: string;
    lastUuid: string;
};

export type ApiIndexHistory = ApiIndexHistoryItem[];

const { single, all } = context;

export async function fetchSearchConfiguration(): Promise<ApiDebugSearchConfiguration> {
    const { attributes } = await single<ApiDebugSearchConfiguration>( 'debug/search-configuration' );

    return attributes;
}

export async function fetchIndexHistory(): Promise<ApiIndexHistory> {
    const { data } = await all<ApiIndexHistoryItem>( 'debug/index-history' );

    return data.map(
        ( { attributes } ): ApiIndexHistoryItem => ( {
            ...attributes,
        } ),
    );
}
