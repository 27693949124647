import { cache, context } from '@main/api';
import type { ListOptions } from '@main/api/plugin/types/api';
import { createMediaItem } from '@main/api/resources/media';
import type { Experiment } from '@main/domain/features';
import { serializeOptions } from '@main/utilities/api';
import type { ResourcePayload } from '@main/utilities/types';

export type ApiUser = {
    readonly uuid: string;
    readonly avatarUrl: string | null;
    readonly createdAt: string;
    readonly emailVerified: boolean;
    readonly facebookConnected: boolean;
    readonly googleConnected: boolean;
    readonly linkedInConnected: boolean;
    readonly twitterConnected: boolean;
    readonly updatedAt: string;
    readonly isCustomerOwner: boolean;

    // mutable
    email: string;
    experiments: Experiment[];
    jobTitle: string | null;
    name: string;
    role: string;
};

export type ApiUserMeta = {
    readonly isCustomerOwner: boolean;
};

export type UpdatedApiUser = ResourcePayload<ApiUser>;

type WritablePasswordUser = {
    password: string | undefined;
} & ApiUser;

export type ApiInvitation = {
    readonly uuid: string;
    readonly email: string;
};

const { all, update, destroy, create } = context;

export async function changePassword( user: string, password: string ) {
    await update<WritablePasswordUser>( `users/${user}`, { password } );
}

export async function updateUser( user: string, data: UpdatedApiUser ) {
    await update<ApiUser>( `users/${user}`, data, {
        invalidates: [/users(\?.*)?/],
    } );
}

export async function fetchUsers( options?: ListOptions<ApiUser> ) {
    const { data, meta, included } = await all<ApiUser, ApiInvitation>( 'users', {
        searchParams: options ? serializeOptions( options ) : undefined,
    } );

    return {
        users: data.map( ( { attributes } ) => attributes ),
        invitations:
            included?.map( ( { attributes } ) => ( {
                ...attributes,
            } ) ) ?? [],
        total: meta?.total ?? -1,
    };
}

export async function updateAvatar( user: string, file: File ) {
    const avatar: string = await createMediaItem( file, 'userAvatar', { longestEdge: 512 } );

    // Store the new avatar UUID for the user
    await update( `users/${user}`, { avatar } );

    const userDataUrl = new URL( '/oauth/userinfo', import.meta.env.VITE_AUTH_AUTHORIZE_ENDPOINT );

    // Drop the current user data from the cache, so it can be fetched
    // with the new data properly. We have to do this manually as the
    // situation is a little special with the userinfo endpoint: The
    // cache will only be flushed for the users/{uuid} URL automatically
    cache.delete( userDataUrl.toString() );
}

export async function deleteUser( user: string ) {
    await destroy( `users/${user}`, {
        invalidates: [/users(\?.*)?/],
    } );
}

export function inviteUser( email: string ) {
    return create<ApiInvitation>(
        'invitations',
        { email },
        {
            invalidates: [/users(\?.*)?/],
            suppressErrorHandling: true,
        },
    );
}

export function deleteInvitation( uuid: string ) {
    return destroy( `invitations/${uuid}`, {
        invalidates: [/users(\?.*)?/],
    } );
}

export const jobTitles: string[] = [
    'Account Manager',
    'Marketing Consultant',
    'Brand Manager',
    'Executive Assistant',
    'Consultant',
    'Procurement Manager',
    'Procurement Director',
    'Head of Procurement',
    'Sales Manager',
    'Sales Director',
    'Head of Sales',
    'Marketing Manager',
    'Marketing Director',
    'Head of Marketing',
    'Other',
    'Prefer not to tell',
];

export const roleAdmin = 'admin';
export const roleBillingManager = 'billing_manager';
export const roleDeveloper = 'developer';
export const roleUser = 'user';

export const roles = [
    {
        name: 'Administrator',
        value: roleAdmin,
    },
    {
        name: 'Billing Manager',
        value: roleBillingManager,
    },
    {
        name: 'Developer',
        value: roleDeveloper,
    },
    {
        name: 'Unprivileged User',
        value: roleUser,
    },
];
