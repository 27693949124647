import type { PluginFunction } from 'vue';
import type { VNodeDirective } from 'vue/types/vnode';

/**
 * All handlers that may be passed by components using the directive. Each
 * translates to the respective event, sans the "drag" for clarity.
 */
type DraggingHandlers = {
    enter?: EventListener;
    leave?: EventListener;
    over?: EventListener;
    drop?: EventListener;
};

/**
 * The arguments passed to the directive may either be a single listener
 * function that will handle all events, or an object containing individual
 * handlers for the events. This type describes the possible values.
 */
type DraggingDirectiveArgs = {
    value?: EventListener | DraggingHandlers;
} & VNodeDirective;

export const draggablePlugin: PluginFunction<unknown> = function draggablePlugin( Vue_ ) {
    // let dragging        = false;
    let count = 0;
    let cancelImmediate = noOp;

    Vue_.directive( 'dragging', {
        inserted( element: HTMLElement, binding: DraggingDirectiveArgs ) {
            const dragEnter: EventListener = ( event: Event ): void => {
                event.preventDefault();

                // Determine whether the binding value is a function or an object of
                // functions, in which case we assume the callback to be present.
                const handler: EventListener | undefined =
                    typeof binding.value === 'function' ? binding.value : binding.value?.enter;

                if ( !handler ) {
                    throw new Error( `Must assign a value to ${binding.name}` );
                }

                if ( count === 0 ) {
                    // dragging = true;

                    handler( event );
                }

                ++count;
            };

            const dragLeave: EventListener = ( event: Event ): void => {
                event.preventDefault();

                // Determine whether the binding value is a function or an object of
                // functions, in which case we assume the callback to be present.
                const handler: EventListener | undefined =
                    typeof binding.value === 'function' ? binding.value : binding.value?.leave;

                if ( !handler ) {
                    throw new Error( `Must assign a value to ${binding.name}` );
                }

                cancelImmediate = setImmediate( () => {
                    --count;

                    if ( count === 0 ) {
                        // dragging = false;

                        handler( event );
                    }
                } );
            };

            const dragOver: EventListener = ( event: Event ): void => {
                event.preventDefault();

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ( event as DragEvent ).dataTransfer!.dropEffect = 'copy';

                const handler: EventListener | undefined =
                    typeof binding.value === 'function' ? binding.value : binding.value?.leave;

                if ( handler ) {
                    handler( event );
                }
            };

            const drop: EventListener = ( event: Event ): void => {
                event.preventDefault();

                cancelImmediate();

                if ( count > 0 ) {
                    count = 0;

                    // dragging = false;
                }

                const handler: EventListener | undefined =
                    typeof binding.value === 'function' ? binding.value : binding.value?.leave;

                if ( handler ) {
                    handler( event );
                }
            };

            element._draggingListeners = [
                createEventListener( 'dragenter', dragEnter ),
                createEventListener( 'dragleave', dragLeave ),
                createEventListener( 'dragover', dragOver ),
                createEventListener( 'drop', drop ),
            ];
        },

        unbind( element: HTMLElement ) {
            if ( !element._draggingListeners ) {
                return;
            }

            // Remove all listeners
            element._draggingListeners.forEach( ( fn ) => fn() );
        },
    } );
};

type Listener<T extends keyof DocumentEventMap> = (
    this: Document,
    event: DocumentEventMap[T],
) => unknown;

/**
 * Adds an event listener to the document and returns a function to remove the
 * listener again.
 *
 * @param type
 * @param listener
 * @param options
 */
function createEventListener<T extends keyof DocumentEventMap>(
    type: T,
    listener: Listener<T>,
    options?: boolean | AddEventListenerOptions,
) {
    document.addEventListener( type, listener, options );

    return () => document.removeEventListener( type, listener, options );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noOp() {}

function setImmediate( callback: ( ...args: unknown[] ) => void, ...args: unknown[] ) {
    let cancelled = false;

    Promise.resolve().then( () => cancelled || callback( ...args ) );

    return () => ( cancelled = true );
}
