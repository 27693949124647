import { context } from '@main/api';
import { logout } from '@main/api/auth';
import { METHOD_POST } from '@main/api/plugin/types/api';
import type { Attributes } from '@main/api/plugin/types/jsonApi';
import type { OAuthTokenRequest } from '@main/api/plugin/types/oauth';
import type { ApiUser, ApiUserMeta } from '@main/api/resources/users';
import ky from 'ky';

const { single } = context;

export function generateAuthorizationLink( state: string, challenge: string ) {
    const signInUrl = new URL( import.meta.env.VITE_AUTH_AUTHORIZE_ENDPOINT );
    signInUrl.searchParams.append( 'response_type', 'code' );
    signInUrl.searchParams.append( 'client_id', import.meta.env.VITE_AUTH_CLIENT_ID );
    signInUrl.searchParams.append( 'redirect_uri', import.meta.env.VITE_AUTH_REDIRECT_URI );
    signInUrl.searchParams.append( 'scope', import.meta.env.VITE_AUTH_SCOPES );
    signInUrl.searchParams.append( 'state', state );
    signInUrl.searchParams.append( 'code_challenge_method', 'S256' );
    signInUrl.searchParams.append( 'code_challenge', challenge );

    return signInUrl;
}

export async function fetchTokenWithAuthCode(
    code: string,
    verifier: string,
    scopes: string[] | null = null,
) {
    return requestAccessToken( import.meta.env.VITE_AUTH_TOKEN_ENDPOINT, {
        client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
        code: code,
        code_verifier: verifier,
        grant_type: 'authorization_code',
        redirect_uri: import.meta.env.VITE_AUTH_REDIRECT_URI,
        scope: scopes ? scopes.join( ' ' ) : import.meta.env.VITE_AUTH_SCOPES,
    } );
}

export async function fetchTokenWithRfxSecret( rfxSecret: string, scopes: string[] | null = null ) {
    const authEndpoint = import.meta.env.VITE_AUTH_TOKEN_ENDPOINT;
    const clientId = import.meta.env.VITE_AUTH_RFX_CLIENT_ID;

    const scope = scopes ? scopes.join( ' ' ) : import.meta.env.VITE_AUTH_RFX_SCOPES;

    return requestAccessToken( authEndpoint, {
        client_id: clientId,
        client_secret: rfxSecret,
        grant_type: 'client_credentials',
        scope: scope,
    } );
}

export async function fetchUserInfo(): Promise<ApiUser> {
    const url = new URL( '/oauth/userinfo', import.meta.env.VITE_AUTH_AUTHORIZE_ENDPOINT );

    const { attributes, meta } = await single<ApiUser, Attributes, ApiUserMeta>( url, {
        prefixUrl: '',
    } );

    return {
        ...attributes,
        isCustomerOwner: meta?.isCustomerOwner ?? false,
    };
}

export async function signOut() {
    await logout();
}

async function requestAccessToken( endpoint: string, body: OAuthTokenRequest ): Promise<Credentials> {
    const response = await ky( endpoint, {
        body: new URLSearchParams( body as Record<string, string> ),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        },

        // OAuth uses URL encoded form bodies as per RFC6749. See:
        // https://tools.ietf.org/html/rfc6749#section-4.1.3
        method: METHOD_POST,
    } );
    const requestedScope = body.scope ?? '';
    const responseBody = ( await response.json() ) as Record<string, unknown>;

    // Scopes are always delimited by a space character. See:
    // https://tools.ietf.org/html/rfc6749#section-3.3
    const scopes = ( responseBody.scope ?? requestedScope ).toString().split( ' ' );
    const token = responseBody.access_token!.toString();
    const expiration = new Date();

    expiration.setSeconds( Number( responseBody.expires_in ) );

    return { expiration, scopes, token };
}

type Credentials = {
    token: string;
    expiration: Date;
    scopes: string[];
};
