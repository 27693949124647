import type { ApiError as ErrorObject } from '@main/api/plugin/types/jsonApi';

/**
 * Wrapper error providing access to the errors received from the API
 */
export default class ApiError<S extends number = 500> extends Error {
    public readonly errors: ErrorObject[];

    public readonly response: Response;

    constructor( message: string, errors: ErrorObject[], response: Response ) {
        super( message );
        this.name = 'ApiError';
        this.stack = this.stack?.split( '\n' ).slice( 1 ).join( '\n' );

        this.errors = errors;
        this.response = response;
    }

    get status(): S {
        return this.response.status as S;
    }
}

export function is404ApiError( error: unknown ): error is ApiError<404> {
    return isApiErrorStatus( error, 404 );
}

export function is409ApiError( error: unknown ): error is ApiError<409> {
    return isApiErrorStatus( error, 409 );
}

export function is429ApiError( error: unknown ): error is ApiError<429> {
    return isApiErrorStatus( error, 429 );
}

export function isApiErrorStatus<S extends number>(
    error: unknown,
    status: S,
): error is ApiError<S> {
    if ( !( error instanceof ApiError ) ) {
        return false;
    }

    return error.status === status;
}

/**
 * Type guard for expected errors to reduce noise in Sentry.
 */
export function isSampledError( error: unknown ): error is SampledError {
    return (
        error !== null &&
        typeof error === 'object' &&
        'getSamplingRate' in error &&
        typeof error.getSamplingRate === 'function'
    );
}

export type SampledError = {
    getSamplingRate(): number;
};
