import type { Country } from 'countries-list';
import { countries } from 'countries-list';
import countries3to2 from 'countries-list/dist/countries3to2.json';
import { bbox } from 'topojson-client';
import type {
    ArcIndexes,
    GeometryCollection,
    GeometryObject,
    MultiPolygon,
    Polygon,
    Topology,
} from 'topojson-specification';

export type GeoBoundingBox = {
    minLongitude: number;
    minLatitude: number;
    maxLongitude: number;
    maxLatitude: number;
};

export type Point = {
    latitude: number;
    longitude: number;
};

export function countryNameFromAlpha3( alpha3: string ) {
    const alpha2 = ( countries3to2 as Record<string, string> )[alpha3];

    // This should be possible®
    if ( !alpha2 ) {
        return alpha3;
    }

    const country = ( countries as Record<string, Country> )[alpha2];

    return country ? country.name : alpha3;
}

export type CountryTopology = Topology<{ countries: GeometryCollection<{ name: string; }>; }>;

const cache = new Map<string, GeoBoundingBox | undefined>();

export function boundingBox( geoData: CountryTopology, alpha3: string ) {
    if ( cache.has( alpha3 ) ) {
        return cache.get( alpha3 );
    }

    const { geometries } = geoData.objects.countries;
    const country = ( geometries as ( Polygon | MultiPolygon )[] ).find( ( item ) => item.id === alpha3 );

    if ( !country ) {
        cache.set( alpha3, undefined );

        return undefined;
    }

    // Fetch arcs for given country
    const arcs = flatArcs( country.arcs, geoData );
    const countryTopology = {
        type: 'Topology' as const,
        transform: geoData.transform,

        // TODO: Figure out why the types don't match - something is fishy here.
        //       Topology expects number[][][], but we got number[][]
        arcs: arcs as unknown as ArcIndexes[][],
        objects: {},
    };

    try {
        const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox( countryTopology );
        const boundingBox: GeoBoundingBox = {
            minLongitude,
            minLatitude,
            maxLongitude,
            maxLatitude,
        };

        cache.set( alpha3, boundingBox );

        return boundingBox;
    } catch ( error ) {
        console.error( `Failed to calculate bounding box for ${alpha3}`, error );

        return undefined;
    }
}

/**
 * bbox expects an array of arrays.
 *
 * @param arcs
 * @param geoData
 * @see https://github.com/topojson/topojson-client/blob/master/src/bbox.js
 * @see https://github.com/topojson/topojson-specification/blob/master/README.md#214-arc-indexes
 * @see https://github.com/topojson/topojson-specification/blob/master/README.md#22-geometry-objects
 */
function flatArcs( arcs: ArcIndexes[] | ArcIndexes[][], geoData: CountryTopology ) {
    const flat: ArcIndexes[] = [];

    function flatten( arc: ArcIndexes | ArcIndexes[] ) {
        arc.forEach( ( index: number | number[] ) => {
            if ( Array.isArray( index ) ) {
                // Used to be flat.concat(flatten(index));
                // but I (Stefan) don't think this is correct.
                flatten( index );
            } else {
                if ( index >= 0 ) {
                    flat.push( geoData.arcs[index] as [] );
                } else {
                    flat.push( geoData.arcs[~index] as [] );
                }
            }
        } );
    }

    arcs.forEach( ( arc ) => flatten( arc ) );

    return flat;
}

export function isPointInCountry(
    geoData: CountryTopology,
    longitude: number,
    latitude: number,
    alpha3: string,
) {
    const box = boundingBox( geoData, alpha3 );

    // We just don't know and let the caller handle this.
    if ( !box ) {
        return 'unknown';
    }

    return (
        longitude >= box.minLongitude &&
        longitude <= box.maxLongitude &&
        latitude >= box.minLatitude &&
        latitude <= box.maxLatitude
    );
}

export function centerOfCountry( geoData: CountryTopology, alpha3: string ) {
    const box = boundingBox( geoData, alpha3 );

    // We just don't know and let the caller handle this.
    if ( !box ) {
        return undefined;
    }

    const { minLongitude, minLatitude, maxLongitude, maxLatitude } = box;
    const longitude = minLongitude + ( maxLongitude - minLongitude ) / 2;
    const latitude = minLatitude + ( maxLatitude - minLatitude ) / 2;

    return { longitude, latitude } satisfies Point;
}

/**
 * Calculates the center of an arbitrary amount of geo-coordinates. This places
 * the coordinates on a sphere and resolves the point with the smallest distance
 * to all of them.
 * My maths teacher would be so proud.
 *
 * @param coordinates
 */
export function centerOfCoordinates( coordinates: Coordinate[] ): [number, number] {
    const total = coordinates.length;

    if ( total === 0 ) {
        throw new Error( 'No coordinates provided' );
    }

    let x = 0;
    let y = 0;
    let z = 0;

    for ( const coordinate of coordinates ) {
        // Decimal to radians
        const latitude = ( coordinate.latitude * Math.PI ) / 180;
        const longitude = ( coordinate.longitude * Math.PI ) / 180;

        x += Math.cos( latitude ) * Math.cos( longitude );
        y += Math.cos( latitude ) * Math.sin( longitude );
        z += Math.sin( latitude );
    }

    x = x / total;
    y = y / total;
    z = z / total;

    const centralLongitude = Math.atan2( y, x );
    const centralSquareRoot = Math.sqrt( x * x + y * y );
    const centralLatitude = Math.atan2( z, centralSquareRoot );

    // Radians to decimal
    return [( centralLongitude * 180 ) / Math.PI, ( centralLatitude * 180 ) / Math.PI];
}

export type Coordinate = {
    latitude: number;
    longitude: number;
};

/**
 * Geo JSON delivery module
 * ========================
 * This module is essentially just a really dumb static file server that allows
 * access to the world atlas GeoJSON data. As those files are huge, and only
 * required for a few special use cases (mainly choropleth maps), we want those
 * files to be loaded on-demand only, as blobs.
 * To avoid having to manually copy them to the assets directory (and risking
 * serious trouble should country boundaries change in the future without us
 * noticing, for example), we add new endpoints to Nuxt here which dispatch
 * those files directly.
 */
export async function loadGeoJsonTopology() {
    if ( geoJsonTopology ) {
        return geoJsonTopology;
    }

    // Async, parallel import with type override. Yuck!
    const [worldAtlas, countries2to3] = ( await Promise.all( [
        import( 'world-atlas/countries-110m.json' ),
        import( 'countries-list/dist/countries2to3.json' ),
    ] ) ) as unknown as [WorldTopology, Record<string, string>];

    const landGeometry = worldAtlas.objects.land;
    const countryGeometry = worldAtlas.objects.countries;
    const mappedCountries = Object.entries( countries ).reduce<Record<string, string>>(
        ( carry, [code, { name }] ) => {
            const alternativeName = alternativeNames[name] ? alternativeNames[name] : name;

            carry[alternativeName] = countries2to3[code];

            return carry;
        },
        {},
    );

    const cleanedGeometry = countryGeometry.geometries

        // Remove Antarctica - no suppliers there. Note to self: This may bite back
        // at some point in the future. With a bit of luck though, you who finally
        // arrive here isn't me :)
        // If so, I'm sorry. This was a pretty good conclusion at the time
        // of writing.
        .filter( ( { id } ) => id !== '010' )

        // This is a simple type guard that ensures there's no null geometry in the
        // data. Should not happen, but makes TS happy.
        .filter( ( geometry ): geometry is NamedGeometry => geometry.type !== null )

        // Map the geometry and replace the numeric ID by the alpha3
        // country code. This makes it a lot easier to correlate the
        // data later on.
        .map( ( geometry ) => {
            const { name = '' } =
                geometry.properties && 'name' in geometry.properties ? geometry.properties : {};
            const id = mappedCountries[name];

            return { ...geometry, id };
        } );

    // Map the data to alpha 3, remove Antarctica
    geoJsonTopology = {
        ...worldAtlas,
        objects: {
            land: landGeometry,
            countries: {
                type: countryGeometry.type,
                geometries: cleanedGeometry,
            },
        },
    };

    return geoJsonTopology;
}

let geoJsonTopology: WorldTopology | null = null;

type CustomGeometryProperties = { name: string; };

type NamedGeometry = GeometryObject<CustomGeometryProperties>;

type NamedGeometryCollection = GeometryCollection<CustomGeometryProperties>;

export type WorldTopology = Topology<{
    land: NamedGeometryCollection;
    countries: NamedGeometryCollection;
}>;

/**
 * world-atlas (https://github.com/topojson/world-atlas) uses names that differ
 * from country-list for the following countries.
 *
 * country-list provides a native name. However, this does also not match the
 * world-atlas names in all cases. So we might as well map this manually.
 *
 * Here we map the country-list names to the world-atlas names.
 */
const alternativeNames: Record<string, string> = {
    Antarctica: 'Fr. S. Antarctic Lands',
    'Bosnia and Herzegovina': 'Bosnia and Herz.',
    'Central African Republic': 'Central African Rep.',
    'Czech Republic': 'Czechia',
    Cyprus: 'N. Cyprus',
    'Democratic Republic of the Congo': 'Dem. Rep. Congo',
    'Dominican Republic': 'Dominican Rep.',
    'East Timor': 'Timor-Leste',
    'Equatorial Guinea': 'Eq. Guinea',
    'Falkland Islands': 'Falkland Is.',
    'Ivory Coast': "Côte d'Ivoire",
    'Myanmar [Burma]': 'Myanmar',
    'North Macedonia': 'Macedonia',
    'Republic of the Congo': 'Congo',
    'Solomon Islands': 'Solomon Is.',
    Somalia: 'Somaliland',
    'South Sudan': 'S. Sudan',
    'United States': 'United States of America',
    'Western Sahara': 'W. Sahara',

    'NOT-FOUND': 'eSwatini',
};

export const prefixedRegionsMap: Record<string, string> = {
    'Australia and New Zealand': 'Australia and New Zealand',
    Caribbean: 'Caribbean',
    'Central America': 'America - Central',
    'Central Asia': 'Asia - Central',
    'Eastern Africa': 'Africa - Eastern',
    'Eastern Asia': 'Asia - Eastern',
    'Eastern Europe': 'Europe - Eastern',
    Melanesia: 'Melanesia',
    Micronesia: 'Micronesia',
    'Middle Africa': 'Africa - Middle',
    'Northern Africa': 'Africa - Northern',
    'Northern America': 'America - Northern',
    'Northern Europe': 'Europe - Northern',
    Polynesia: 'Polynesia',
    'South America': 'America - South',
    'South-Eastern Asia': 'Asia - South-eastern',
    'Southern Africa': 'Africa - Southern',
    'Southern Asia': 'Asia - Southern',
    'Southern Europe': 'Europe - Southern',
    'Western Africa': 'Africa - Western',
    'Western Asia': 'Asia - Western',
    'Western Europe': 'Europe - Western',
};

/**
 * Longitude values can wrap around and thus exceed the range of -180 to 180°.
 * This function normalizes the value to stay within that range.
 * @param longitude
 */
export function normalizeLongitude( longitude: number ) {
    return ( ( ( ( longitude + 180 ) % 360 ) + 360 ) % 360 ) - 180;
}

/**
 * Ensure a latitude value stays within its specified range of -90 to 90°, by
 * setting it to the nearest limit.
 * @param latitude
 */
export function clampLatitude( latitude: number ) {
    return Math.max( -90, Math.min( 90, latitude ) );
}
