import { maxDocumentSizeBytes } from '@main/api/limits';
import type { Notification } from '@main/store/stores/notifications';

/**
 * Reads a file blob and returns its content as a base64 data URL.
 *
 * @param file
 */
export function readFile( file: File | Blob ): Promise<string | null> {
    const fileReader = new FileReader();

    return new Promise( ( resolve, reject ) => {
        fileReader.onerror = () => {
            fileReader.abort();

            reject( new DOMException( 'Failed to parse input file' ) );
        };

        fileReader.onload = () => resolve( fileReader.result as string );
        fileReader.readAsDataURL( file );
    } );
}

export type ResizeOptions = {
    longestEdge?: number;
    jpegQuality?: number;
    crop?: {
        position?: 'top' | 'center';
        width: number;
        height: number;
    };
};

/**
 * Resizes an image blob by rendering it to a canvas, then reading the content
 * of the canvas as a blob and returning it.
 *
 * @param file
 * @param options
 */
export function resizeImage( file: File, options?: ResizeOptions ): Promise<Blob> {
    function resize( image: HTMLImageElement ): Promise<Blob> {
        const canvas = document.createElement( 'canvas' );

        let width = image.width;
        let height = image.height;

        if ( options?.longestEdge ) {
            if ( width > height ) {
                if ( width > options.longestEdge ) {
                    height *= options.longestEdge / width;
                    width = options.longestEdge;
                }
            } else {
                if ( height > options.longestEdge ) {
                    width *= options.longestEdge / height;
                    height = options.longestEdge;
                }
            }
        }

        if ( options?.crop ) {
            width = Math.min( width, options.crop.width );
            height = Math.min( height, options.crop.height );
        }

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext( '2d' )!;

        if ( options?.crop ) {
            switch ( options.crop?.position ) {
                case 'top':
                    context.drawImage(
                        image,
                        0,
                        0,
                        width,
                        height,
                        0,
                        0,
                        Math.min( image.width, options.crop.width ),
                        Math.min( image.height, options.crop.height ),
                    );
                    break;

                case 'center':
                default:
                    context.drawImage(
                        image,
                        0,
                        0,
                        Math.min( image.width, options.crop.width ),
                        Math.min( image.height, options.crop.height ),
                    );
                    break;
            }
        } else {
            context.drawImage( image, 0, 0, width, height );
        }

        return new Promise<Blob>( ( resolve, reject ): void => {
            canvas.toBlob(
                function ( blob: Blob | null ) {
                    if ( !blob ) {
                        return reject( 'Failed to render image' );
                    }

                    return resolve( blob );
                },
                file.type,
                options?.jpegQuality || 0.6,
            );
        } );
    }

    return new Promise<Blob>( ( resolve, reject ): void => {
        if ( !file.type.match( /image.*/ ) ) {
            return reject( new Error( 'Not an image' ) );
        }

        const reader = new FileReader();
        const image = new Image();

        reader.onload = ( readerEvent: ProgressEvent<FileReader> ): void => {
            image.onload = () => resolve( resize( image ) );
            image.src = readerEvent.target?.result as string;
        };

        reader.readAsDataURL( file );
    } );
}

export function downloadBlob( blob: Blob, filename?: string ) {
    const objectUrl: string = window.URL.createObjectURL( blob );
    const anchor: HTMLAnchorElement = document.createElement( 'a' );

    anchor.style.display = 'none';
    anchor.href = objectUrl;

    anchor.download = filename ?? '';

    document.body.appendChild( anchor );
    anchor.click();
    return anchor;
}

export function verifyFileSizes( files: FileList, notify: ( notification: Notification ) => void ) {
    let abort = false;

    for ( const file of Array.from( files ) ) {
        if ( file.size > maxDocumentSizeBytes ) {
            const readableMaxSize = ( ( maxDocumentSizeBytes as number ) / 1024 / 1024 ).toFixed( 2 );

            notify( {
                message: `${file.name} is too big. Images must be smaller than ${readableMaxSize}MB`,
                type: 'error',
            } );

            abort = true;
            // Continue. Other files might be too big as well.
            // We  want to show all notifications.
        }
    }

    return !abort;
}
