import type { Attributes } from '@main/api/plugin/types/jsonApi';
import type { Options } from 'ky';

/**
 * Flexible search params
 * ======================
 * A simple extension of the constrained variant provided by ky. Allows passing
 * a convenient range of types for query parameters
 */
export type FlexibleSearchParams =
    | string
    | Record<string, string | number | boolean | string[]>
    | ( string | number | boolean )[][]
    | URLSearchParams;

/**
 * Context Request Options
 * =======================
 * Generic options provided by all context request methods
 */
export type ContextRequestOptions = {
    searchParams?: FlexibleSearchParams;
    invalidates?: ( string | RegExp )[];
    suppressErrorHandling?: boolean;
    bypass?: boolean;
} & Omit<Options, 'searchParams'>;

export type CreateResourceContextRequestOptions<T extends boolean | undefined> = {
    // Set to true in order to have the resource returned instead of the UUID.
    // This is temporary, until we changed all create and update methods
    // to use the response's returned resource.
    returnResource?: T;
} & ContextRequestOptions;

export type PaginationOptions = {
    page?: number;
    per_page?: number;
};

export type PaginationResult = {
    total: number;
    currentPage: number;
    perPage: number;
    lastPage: number;
};

export type InclusionOptions<T extends string = string> = {
    include?: T[];
};

export type SortField<T extends string> = T | `-${T}`;

export type SortOptions<T extends Attributes = Attributes> = {
    sort?: SortField<keyof T & string>[];
};

export type FilterOptions<V extends string = string> = {
    filter?: Partial<Record<V, string | string[]>>;
};

// TODO: add support for aggregations
export type ListOptions<
    Entity extends Attributes = Attributes,
    Include extends string = string,
    Filter extends string = string,
> = {
    searchParams?: URLSearchParams;
} & PaginationOptions &
    SortOptions<Entity> &
    FilterOptions<Filter> &
    InclusionOptions<Include>;

// These constants allow to refer to common HTTP methods.
export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_PATCH = 'PATCH';
export const METHOD_DELETE = 'DELETE';
export const METHOD_HEAD = 'HEAD';
export const METHOD_OPTIONS = 'OPTIONS';

// Note that while these type exports have the same names as the constant
// exports, they do not overlap, as the constants are runtime values. This makes
// for a neat feature: We can use both the string 'GET' and the constant
// `METHOD_GET` in places where a parameter has an `HttpMethod` type!
export type METHOD_GET = 'GET';
export type METHOD_POST = 'POST';
export type METHOD_PUT = 'PUT';
export type METHOD_PATCH = 'PATCH';
export type METHOD_DELETE = 'DELETE';
export type METHOD_HEAD = 'HEAD';
export type METHOD_OPTIONS = 'OPTIONS';

// This type simply groups all HTTP methods into a single, reusable type.
export type HttpMethod =
    | METHOD_GET
    | METHOD_POST
    | METHOD_PUT
    | METHOD_PATCH
    | METHOD_DELETE
    | METHOD_HEAD
    | METHOD_OPTIONS;
