import type { ApiQueryAspect } from '@main/api/resources/debug';
import { mdiAlphaABox, mdiAlphaRBox, mdiAlphaTBox } from '@mdi/js';

export class QueryAspect {
    constructor( public readonly resource: ApiQueryAspect ) {}

    apiResource() {
        return this.resource;
    }

    id() {
        return this.resource.id;
    }

    name() {
        return this.resource.name;
    }

    version() {
        return this.resource.version;
    }

    nameWithVersion() {
        if ( this.version() === 1 ) {
            return this.name();
        }

        return `${this.name()} - v${this.version()}`;
    }

    description() {
        return this.resource.description;
    }

    boost() {
        return this.resource.defaultBoost;
    }

    defaultBoost() {
        return this.resource.defaultBoost;
    }

    icon() {
        if ( this.resource.isAugmented ) {
            return mdiAlphaABox;
        }

        if ( this.resource.isRankFeature ) {
            return mdiAlphaRBox;
        }

        return mdiAlphaTBox;
    }

    color() {
        if ( this.resource.isAugmented ) {
            return 'blue';
        }

        if ( this.resource.isRankFeature ) {
            return 'yellow';
        }

        return 'green';
    }
}
