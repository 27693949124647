import { clearCache, getCachedEndpoints } from '@main/api';
import {
    type ApiDebugSearchConfiguration,
    type ApiIndexHistory,
    type DebugFetchResults,
    fetchIndexHistory,
    fetchSearchConfiguration,
} from '@main/api/resources/debug';
import { IndexHistory } from '@main/domain/debug/IndexHistory';
import { QueryAspect } from '@main/domain/debug/QueryAspect';
import { useAuthStore } from '@main/store/stores/auth';
import { useNotificationsStore } from '@main/store/stores/notifications';
import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue from 'vue';

type State = {
    /**
     * The data points that can be enabled and disabled in the search results,
     * when debug data is enabled.
     */
    dataPointItems: DataPointItem[];
    dataPoints: DataPointItem[];

    /**
     * True, in order to load and show additional data in various features, such
     * as search results.
     */
    debugDataIsEnabled: boolean;

    /**
     * True, in order to configure the search query and investigate
     * search results.
     */
    debugQueryIsEnabled: boolean;

    /**
     * True per panel name if it is expanded.
     */
    disclosurePanelsExpanded: Record<string, boolean>;

    /**
     * Query explanation.
     */
    explanationAsTreeView: boolean;
    queryAspectBoosts: Record<string, number>;

    /**
     * The IDs of aspects and the boosts that are currently enabled.
     */
    queryAspects: string[];
    queryConditionBoosts: string | null;

    /**
     * The aspects and boosts that can be enabled and disabled.
     */
    searchConfiguration: ApiDebugSearchConfiguration | null;
    skipEmptyExplanationDetails: boolean;
    skipZeroExplanationValues: boolean;

    /**
     * The history of indices that have been released.
     */
    apiIndexHistory: ApiIndexHistory | null;
};

/*
 * This store encapsulates debug settings of user whose debug-feature is enabled
 *  in the backend.
 */
export const useDebugStore = defineStore( 'debug', {
    persist: true,

    state(): State {
        return {
            dataPointItems: dataPointItems(),
            dataPoints: defaultDataPoints(),
            debugDataIsEnabled: false,
            debugQueryIsEnabled: false,
            disclosurePanelsExpanded: {},
            explanationAsTreeView: true,
            queryAspectBoosts: {},
            queryAspects: [],
            queryConditionBoosts: null,
            searchConfiguration: null,
            skipEmptyExplanationDetails: true,
            skipZeroExplanationValues: true,
            apiIndexHistory: null,
        };
    },

    actions: {
        flush() {
            this.debugDataIsEnabled = false;
            this.debugQueryIsEnabled = false;
            this.explanationAsTreeView = true;
            this.disclosurePanelsExpanded = {};
            this.searchConfiguration = null;
            this.skipEmptyExplanationDetails = true;
            this.skipZeroExplanationValues = true;
            this.apiIndexHistory = null;

            this.resetSelectedAspects();
            this.resetSelectedConditionBoosts();
            this.resetDataPoints();

            this.clearCache();
        },

        async setDebugDataEnabled( enabled: boolean ) {
            if ( !this.isAvailable ) {
                return;
            }

            this.debugDataIsEnabled = enabled;

            if ( enabled ) {
                await this.initIndexHistory();
                await this.initSearchConfiguration();
            }
        },

        async setDebugQueryEnabled( enabled: boolean ) {
            if ( !this.isAvailable ) {
                return;
            }

            this.debugQueryIsEnabled = enabled;

            if ( enabled ) {
                await this.initSearchConfiguration();
            }
        },

        async initIndexHistory() {
            if ( !this.isAvailable ) {
                return;
            }

            if ( this.indexHistory ) {
                return;
            }

            this.apiIndexHistory = await fetchIndexHistory();
        },

        async initSearchConfiguration() {
            if ( !this.isAvailable ) {
                return;
            }

            if ( this.searchConfiguration ) {
                return;
            }

            this.searchConfiguration = await fetchSearchConfiguration();
            this.resetSelectedAspects();
            this.resetSelectedConditionBoosts();
        },

        setSelectedAspects( aspectIds: string[] ) {
            if ( !this.isAvailable ) {
                return;
            }

            this.queryAspects.splice( 0, this.queryAspects.length, ...aspectIds );
        },

        resetSelectedAspects() {
            if ( !this.isAvailable ) {
                return;
            }

            this.queryAspects.splice(
                0,
                this.queryAspects.length,
                ...this.productionQueryAspects.map( ( aspect ) => aspect.id() ),
            );

            this.queryAspectBoosts = this.allQueryAspects.reduce<Record<string, number>>(
                ( boosts, aspect ) => ( {
                    ...boosts,
                    [aspect.id()]: aspect.defaultBoost(),
                } ),
                {},
            );
        },

        setSelectedConditionBoosts( conditionBoosts: string ) {
            if ( !this.isAvailable ) {
                return;
            }

            this.queryConditionBoosts = conditionBoosts;
        },

        setDisclosurePanel( name: string, expanded: boolean ) {
            if ( !this.isAvailable ) {
                return;
            }

            Vue.set( this.disclosurePanelsExpanded, name, expanded );
        },

        resetSelectedConditionBoosts() {
            if ( !this.isAvailable ) {
                return;
            }

            this.queryConditionBoosts =
                this.searchConfiguration?.conditionBoosts.filter(
                    ( { name } ) => name === 'Production',
                )[0].id || '';
        },

        resetDataPoints() {
            this.dataPointItems.splice( 0, this.dataPointItems.length, ...dataPointItems() );
            this.dataPoints.splice( 0, this.dataPoints.length, ...defaultDataPoints() );
        },

        showCache() {
            if ( !this.isAvailable ) {
                return;
            }

            useNotificationsStore().notify( {
                detail: getCachedEndpoints(),
                message: 'Cached endpoints:',
                persistent: true,
                type: 'info',
            } );
        },

        clearCache() {
            if ( !this.isAvailable ) {
                return;
            }

            clearCache();
            useNotificationsStore().notify( {
                detail: 'Note, that any backend caching may still apply.',
                message: 'Client cache cleared.',
                type: 'info',
            } );
        },
    },

    getters: {
        isAvailable() {
            return useAuthStore().isFeatureEnabled( 'debug' );
        },

        isDebugDataEnabled( { debugDataIsEnabled } ): boolean {
            if ( !this.isAvailable ) {
                return false;
            }

            return debugDataIsEnabled;
        },

        isDebugQueryEnabled( { debugQueryIsEnabled } ): boolean {
            if ( !this.isAvailable ) {
                return false;
            }

            return debugQueryIsEnabled;
        },

        indexHistory(): IndexHistory | null {
            if ( !this.isAvailable ) {
                return null;
            }

            if ( !this.apiIndexHistory ) {
                return null;
            }

            return new IndexHistory( this.apiIndexHistory );
        },

        allQueryAspects(): QueryAspect[] {
            return [...this.productionQueryAspects, ...this.experimentalQueryAspects];
        },

        productionQueryAspects( { searchConfiguration } ) {
            return ( searchConfiguration?.productionAspects || [] ).map(
                ( aspect ) => new QueryAspect( aspect ),
            );
        },

        experimentalQueryAspects( { searchConfiguration } ) {
            return ( searchConfiguration?.experimentalAspects || [] ).map(
                ( aspect ) => new QueryAspect( aspect ),
            );
        },

        selectedAspects( { queryAspects } ) {
            return queryAspects;
        },

        getAspect(): ( id: string, version?: string ) => QueryAspect | null {
            return ( id, version ) => {
                const combinedId = version ? `${id}:${version}` : id;

                return this.allQueryAspects.find( ( aspect ) => aspect.id() === combinedId ) || null;
            };
        },

        defaultAspectBoost(): ( aspectId: string ) => number {
            return ( aspectId ) =>
                this.allQueryAspects.find( ( aspect ) => aspect.id() === aspectId )?.defaultBoost() ||
                0;
        },

        selectedAspectBoosts( { queryAspectBoosts } ) {
            return queryAspectBoosts;
        },

        conditionBoosts( { searchConfiguration } ) {
            return searchConfiguration?.conditionBoosts || [];
        },

        selectedConditionBoosts( { queryConditionBoosts } ) {
            return queryConditionBoosts || '';
        },

        isDisclosurePanelExpanded(): ( name: string ) => boolean {
            return ( name: string ) => {
                if ( !this.isAvailable ) {
                    return false;
                }

                return this.disclosurePanelsExpanded[name] || false;
            };
        },

        fetchResultsParameters( {
            debugDataIsEnabled,
            debugQueryIsEnabled,
            queryAspects,
        } ): DebugFetchResults | null {
            if ( !this.isAvailable ) {
                return null;
            }

            if ( !debugDataIsEnabled && !debugQueryIsEnabled ) {
                return null;
            }

            if ( !debugQueryIsEnabled ) {
                return {
                    includeDebugData: debugDataIsEnabled ? 1 : 0,
                };
            }

            const aspects = queryAspects.reduce<Record<string, number>>(
                ( aspects, aspect ) => ( {
                    ...aspects,
                    [aspect]: this.queryAspectBoosts[aspect],
                } ),
                {},
            );

            return {
                includeDebugData: this.debugDataIsEnabled ? 1 : 0,
                ...aspects,
                [this.queryConditionBoosts || '']: 1,
            };
        },
    },
} );

function dataPointItems(): DataPointItem[] {
    return [
        'Actions',
        'Categories',
        'Index',
        'Matched Aspects',
        'Product Focus',
        'Score',
        'Shipments',
        'Size',
        'Taxonomy',
        'UUID',
        'Logos',
    ];
}

function defaultDataPoints(): DataPointItem[] {
    return ['Actions', 'Index', 'Score', 'Matched Aspects', 'UUID'];
}

export type DataPointItem =
    | 'Actions'
    | 'Categories'
    | 'Index'
    | 'Matched Aspects'
    | 'Product Focus'
    | 'Score'
    | 'Shipments'
    | 'Size'
    | 'Taxonomy'
    | 'UUID'
    | 'Logos';

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useDebugStore, import.meta.hot ) );
}

export type DebugStore = ReturnType<typeof useDebugStore>;
