/**
 * Generates a random string with an arbitrary number of characters.
 *
 * @param amount
 */
export function noise( amount = 16 ): string {
    return [...Array( amount )].map( () => Math.random().toString( 36 )[2] ).join( '' );
}

/**
 * Sleeps for a given amount of time, passed as milliseconds.
 *
 * @param ms
 */
export function sleep( ms: number ) {
    return new Promise<void>( ( resolve ) => setTimeout( () => resolve(), ms ) );
}

export function chunkArray<T>( array: T[], chunkSize: number ): T[][] {
    return array.reduce( ( result: T[][], item: T, index: number ) => {
        const chunkIndex: number = Math.floor( index / chunkSize );

        if ( !result[chunkIndex] ) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push( item );

        return result;
    }, [] as T[][] );
}

export function stringFrom( subject: string, position: number, length = -1 ): string {
    // Replacement for basic functionality of deprecated substr.
    if ( length === -1 ) {
        return subject.substring( position );
    }

    return subject.substring( position, position + length );
}
