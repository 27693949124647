import { persistentStoragePlugin } from '@main/store/plugins/persistentStorage';
import type { Pinia, PiniaPlugin } from 'pinia';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import Vue from 'vue';

export default usePinia( [
    // Allow for persisting store states.
    piniaPluginPersistedState,
    // Provide access to all persisted stores.
    persistentStoragePlugin,
] );

export function usePinia( plugins: PiniaPlugin[] ) {
    Vue.use( PiniaVuePlugin );

    const pinia: Pinia = createPinia();

    for ( const plugin of plugins ) {
        pinia.use( plugin );
    }

    return pinia;
}
