export class FriendlyError extends Error {
    public originalError: Error | null;

    public detail: string | null;

    constructor( message: string, detail: string | null = null, originalError: Error | null = null ) {
        super( originalError ? originalError.message : message );
        this.name = 'FriendlyError';
        this.stack = this.stack?.split( '\n' ).slice( 1 ).join( '\n' );

        this.originalError = originalError;
        this.detail = detail;
    }

    get fullMessage(): string {
        let message = `An error occurred: ${this.message}`;

        if ( this.detail && this.detail !== this.message ) {
            message = message.replace( /([.!])$/, '' );
            message += `. Additional details: ${this.detail}`;
        }

        return message;
    }
}
