/*
 * ProjectPhases provides methods to interact with a project phases
 * avoiding code duplication in Vue components.
 */

import type { ApiProjectPhase, ProjectPhaseType } from '@main/api/resources/projects';

export class ProjectPhases {
    resource: ApiProjectPhase[];

    constructor( resource: ApiProjectPhase[] ) {
        // Clone array, in order not to mess with the Vue store
        // which we might have got the resource from.
        // See usage of sort operation, which acts in place.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        this.resource = resource?.map( ( p ) => p ) ?? [];
    }

    get( phaseUuid: string ) {
        return this.resource.find( ( { uuid } ) => uuid === phaseUuid );
    }

    getByType( phaseType: ProjectPhaseType ) {
        if ( this.resource.length === 0 ) {
            return null;
        }

        const phase = this.resource.find( ( { type } ) => type === phaseType );

        if ( !phase ) {
            throw new Error( `Phase type '${phaseType}' not found` );
        }

        return phase;
    }

    all() {
        const phases = [...this.resource];

        return phases.sort( ( a, b ) => {
            return sortedPhases.indexOf( a.type ) - sortedPhases.indexOf( b.type );
        } );
    }

    allExceptFor( phaseUuid: string ) {
        return this.resource.filter( ( p ) => p.uuid !== phaseUuid );
    }

    initial() {
        if ( this.resource.length === 0 ) {
            return null;
        }

        return this.all()[0];
    }

    final() {
        if ( this.resource.length === 0 ) {
            return null;
        }

        return this.all().reverse()[0];
    }

    isInitial( phaseUuid: string ) {
        return this.initial()?.uuid === phaseUuid;
    }

    orderNumber( phaseUuid: string ) {
        return this.all().findIndex( ( p ) => p.uuid === phaseUuid ) + 1;
    }

    isFinal( phaseUuid: string ) {
        return this.final()?.uuid === phaseUuid;
    }
}

export const sortedPhases = ['longlist', 'shortlist', 'active', 'deselected'] as const;
