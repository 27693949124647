import { useAuthStore } from '@main/store/stores/auth';
import { useCustomerStore } from '@main/store/stores/customer';
import type { PluginFunction } from 'vue';
import { watch } from 'vue';

export const contextPlugin: PluginFunction<unknown> = async function contextPlugin( Vue_ ) {
    let installed = false;
    let loaded = false;

    // We put the subscription checking into a beforeMount hook, so it will be
    // executed once Vue is ready and an actual component is loaded.
    Vue_.mixin( {
        async mounted() {
            if ( installed ) {
                return;
            }

            installed = true;

            const authStore = useAuthStore();
            const customerStore = useCustomerStore();

            async function loadContext() {
                if ( loaded || !authStore.authenticated ) {
                    return;
                }

                loaded = true;

                await Promise.all( [customerStore.fetchCustomer()] );
            }

            watch( authStore.$state, () => loadContext() );
            await loadContext();
        },
    } );
};

export type ContextInfo = {
    isSupplier: () => boolean;
    isPurchaser: () => boolean;
};

/**
 * Redirects the user to the onboarding page.
 */
// No usage of any onboarding component currently.
// The code above could check for onboarding-state and subscription
// in the future and decide to redirect the user in order to finish onboarding.

// async function redirectToOnboarding( router: VueRouter ): Promise<number | undefined> {
//     const route: Route = router.currentRoute;
//     console.log( { route } );
//     const isOnboardingRoute = Array.isArray( route.meta )
//                               ? route.meta[ 0 ].onboarding
//                               : route.meta?.onboarding;
//
//     if ( isOnboardingRoute ) {
//         return;
//     }
//
//     // The immediate timeout prevents duplicated navigation errors
//     return setTimeout( () => router.push( {
//         name: ROUTE_ONBOARDING_ROOT,
//     } ), 0 );
// }
