/**
 * Logo exports the current logo of a company based on its name.
 *
 * @export
 * @class Logo
 */

import { hashFromString, placeholderLetters } from '@main/utilities/formatting';

type CompanyAvatarColor = {
    background: string;
    text: string;
};

type CompanyAvatarColors = Record<string, CompanyAvatarColor>;

const companyAvatarColors: CompanyAvatarColors = {
    'variant-one': { background: '#FFFFFF', text: '#D3D3D3' },
};

export class Logo {
    protected readonly name: string | undefined;

    public constructor( name: string | undefined ) {
        this.name = name;
    }

    private colors() {
        if ( !this.name ) {
            return companyAvatarColors['variant-one'];
        }
        return colorFromNameHash( this.name[0] );
    }

    public textColor() {
        return this.colors().text;
    }

    public backgroundColor() {
        return this.colors().background;
    }

    public placeholderLetters() {
        if ( !this.name ) {
            return '';
        }
        return placeholderLetters( this.name );
    }
}

export function colorFromNameHash( name: string ) {
    const hash = hashFromString( name );

    const index = hash % Object.keys( companyAvatarColors ).length;
    const key = Object.keys( companyAvatarColors )[index];

    return companyAvatarColors[key];
}
