import { acceptHMRUpdate, defineStore } from 'pinia';

type State = {
    queue: Notification[];
    current: Notification | undefined;
};

export const useNotificationsStore = defineStore( 'notifications', {
    persist: false,

    state(): State {
        return {
            current: undefined,
            queue: [],
        };
    },

    actions: {
        notify( notification: Notification ) {
            this.queue.push( notification );

            if ( !this.current ) {
                this.next();
            }
        },

        dismiss() {
            this.next();
        },

        next() {
            this.current = this.queue.shift();

            return this.current;
        },
    },

    getters: {
        pending( { queue } ) {
            return queue.length > 0;
        },
    },
} );

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useNotificationsStore, import.meta.hot ) );
}

export type NotificationType = 'error' | 'info' | 'success';

export type Notification = {
    message: string;
    detail?: string | string[];
    icon?: string | null;
    color?: string;
    type?: NotificationType;
    persistent?: boolean;
    ttl?: number;
};
