/*
 * Project provides methods to interact with a project.
 */

import type { ApiCollaborator, ApiProjectWithPhases } from '@main/api/resources/projects';
import { ProjectPhases } from '@main/domain/projects/ProjectPhases';

export class Project {
    readonly resource: ApiProjectWithPhases;
    readonly phasesInstance: ProjectPhases;
    readonly collaboratorResources: ApiCollaborator[];

    constructor( resource: ApiProjectWithPhases, collaborators: ApiCollaborator[] ) {
        this.resource = resource;
        this.phasesInstance = new ProjectPhases( this.resource.phases );
        this.collaboratorResources = collaborators;
    }

    apiResource() {
        return this.resource;
    }

    uuid() {
        return this.resource.uuid;
    }

    name() {
        return this.resource.name;
    }

    description() {
        return this.resource.description || '';
    }

    tags() {
        return this.resource.tags;
    }

    keywords(): string[] {
        return this.resource.keywords;
    }

    aiEnabled() {
        return this.resource.aiEnabled;
    }

    setupAssistantConversation() {
        return this.resource.setupAssistantConversation;
    }

    wizardState() {
        return this.resource.wizardState;
    }

    isFinalized() {
        return this.resource.wizardState === 'finalized';
    }

    hasImage() {
        return !!this.resource.imageUrl;
    }

    imageUrl() {
        return this.resource.imageUrl || '';
    }

    createdAt() {
        return this.resource.createdAt;
    }

    createdAtDate() {
        return new Date( this.createdAt() ).toLocaleDateString();
    }

    phases() {
        return this.phasesInstance;
    }

    collaborators() {
        return this.collaboratorResources;
    }

    isOwnedBy( { uuid }: ApiCollaborator ) {
        return this.resource.owner === uuid;
    }

    // region Permissions of current user

    canAddCollaborators() {
        return this.resource.permissions.addCollaborators;
    }

    canRead() {
        return this.resource.permissions.read;
    }

    canWrite() {
        return this.resource.permissions.write;
    }

    canDelete() {
        // TODO-PROJECT-ACTIONS: used to be allowed for project owner,
        // but is the project owner ever allowed to have read-only access?
        return this.resource.permissions.write;
    }

    // endregion
}
