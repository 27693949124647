import type { ListOptions } from '@main/api/plugin/types/api';
import type {
    Attributes,
    DefaultMeta,
    Included,
    Links,
    Meta,
    Relationships,
    ResourceObject,
} from '@main/api/plugin/types/jsonApi';

/**
 * Hydrates relationships from a JSON:API response.
 *
 * @param relationships
 * @param included
 */
export function hydrateRelationships<T extends Attributes>(
    relationships: Relationships | undefined,
    included: Included<T> | undefined,
) {
    // Create a dictionary of all included resources, so we can access them
    // nested and avoid having to do array lookups in the loop below.
    const sorted = ( included ?? [] ).reduce<Record<string, Record<string, T>>>(
        ( carry, item ) => ( {
            ...carry,
            [item.type]: {
                ...( carry[item.type] ?? {} ),
                [item.id]: {
                    ...item.attributes,
                    ...( item.meta ?? {} ),
                },
            },
        } ),
        {},
    );

    const related = Object

        // Ensure we have relationship entries
        .entries( relationships ?? {} )

        // Map the resource identifier to its actual resource representation.
        .map( ( [relationship, { data }] ): [string, ( T | null ) | ( T | null )[]] => {
            // Tell TS that data is available and void non-null assertions below.
            if ( data === null || data === undefined ) {
                throw new Error( 'Unexpected data.' );
            }

            return [
                relationship,
                Array.isArray( data )
                    ? data
                          .map( ( { type, id } ) => ( sorted[type] ? sorted[type][id] : null ) )
                          .filter( ( e ) => !!e )
                    : sorted[data.type]
                      ? sorted[data.type][data.id]
                      : null,
            ];
        } )
        .filter( ( [, v] ) => ( Array.isArray( v ) ? v.length > 0 : !!v ) );

    // Return an object again, containing all included relationships with their
    // resources mapped.
    return Object.fromEntries( related );
}

export function serializeOptions<T extends ListOptions>( options: T ): URLSearchParams {
    return Object.entries( options )
        .filter( ( [key, value] ) => typeof value !== 'undefined' && key !== 'searchParams' )
        .reduce( ( params, [key, value] ): URLSearchParams => {
            if ( typeof value !== 'object' ) {
                params.append( key, value.toString() );

                return params;
            }

            Object.entries( value )
                .filter( ( [, nestedValue] ) => typeof nestedValue !== 'undefined' )
                .forEach( ( [nestedKey, nestedValue] ) =>
                    params.append( `${key}[${nestedKey}]`, nestedValue as string ),
                );

            return params;
        }, options.searchParams ?? new URLSearchParams() );
}

/**
 * Filter function factory: Returns a function that will compare the type
 * attribute of a resource object to the given type. This function may be used
 * as a type guard - if it is passed a generic type, and used in an array filter
 * chain, it will narrow the type of the array to a list of the provided type.
 *
 * @example ResourceObject<Foo | Bar | Baz>[]
 *              .filter(isType<Foo>('foo'))
 *              .map( resource => console.log('resource is typed <Foo> here!') )
 *
 * @param expected
 */
export function isType<V extends T, T extends Attributes = Attributes>(
    expected: ResourceObject<V>['type'],
) {
    return ( resource: ResourceObject<T> ): resource is ResourceObject<V> =>
        resource.type === expected;
}

export function extractPagination<T extends DefaultMeta>( meta?: T ) {
    const { currentPage = 0, lastPage = 0, perPage = 0, total = 0 } = meta ?? {};

    return { currentPage, lastPage, perPage, total };
}

export function extractLink<
    L extends Links,
    T extends ResourceObject<Attributes, Meta, Included, L>,
>( response: Pick<T, 'links' | 'id'>, identifier: keyof L & string, required: true ): string;
export function extractLink<
    L extends Links,
    T extends ResourceObject<Attributes, Meta, Included, L>,
>(
    response: Pick<T, 'links'>,
    identifier: keyof L & string,
    required?: false | undefined,
): string | null;
export function extractLink<
    L extends Links,
    T extends ResourceObject<Attributes, Meta, Included, L>,
>(
    response: Pick<T, 'links' | 'id'> | Pick<T, 'links'>,
    identifier: keyof L & string,
    required = false,
) {
    if ( !response.links || !response.links[identifier] ) {
        if ( !required ) {
            return null;
        }

        const id = ( response as Pick<T, 'links' | 'id'> ).id;

        throw new Error( `Resource ${id} is missing link "${identifier}".` );
    }

    const link = response.links[identifier];

    if ( typeof link === 'string' ) {
        return link;
    }

    return link.href;
}

export function extractLinkedResourceId<
    L extends Links,
    T extends ResourceObject<Attributes, Meta, Included, L>,
>( response: Pick<T, 'links' | 'id'>, identifier: keyof L & string ): string {
    const href = extractLink( response, identifier, true );
    const id = href.split( '/' ).pop() ?? null;

    if ( !id ) {
        throw new Error( `Resource ${response.id} is missing linked ${identifier} resource.` );
    }

    return id;
}

export function forceInclude<T extends ListOptions<Attributes, I>, I extends string>(
    forceInclude: Exclude<T['include'], undefined>,
    options?: T,
) {
    return Array.from( new Set( [...( options?.include ?? [] ), ...forceInclude] ) );
}

// Const, in order to more easily identify all places,
// where we want to load all entities, instead of paginating them.
export const PSEUDO_LOAD_ALL_PARAMETER = 999;
