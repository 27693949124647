import type { MaybePromise } from '@main/utilities/types';
import type { Store } from 'pinia';

export const persistentStorage: PersistentStorage = { registerStore, flushAll };

export async function flushAll() {
    await Promise.all(
        persistentStoreIds().map( ( id ) => {
            getStore( id ).flush();
            localStorage.removeItem( id );
        } ),
    );
}

const persistentStoresById: Record<string, PersistentStore> = {};

function getStore( id: keyof typeof persistentStoresById ) {
    return persistentStoresById[id];
}

function persistentStoreIds() {
    return Object.getOwnPropertyNames( persistentStoresById );
}

function registerStore( store: PersistentStore ) {
    if ( !store.flush ) {
        // All persistent stores are expected to implement a flush action, which
        // is supposed to clean up any initialization that depends on the store's state.
        throw new Error( `Persistent ${store.$id} store is expected to implement flush() action.` );
    }

    persistentStoresById[store.$id] = store;
}

export type PersistentStorage = {
    registerStore( store: Store ): void;
    flushAll(): MaybePromise<void>;
};

type PersistentStore = Store & { flush: () => MaybePromise<void>; };
