import { isHttpErrorStatus } from '@main/api/plugin/context';
import {
    fetchTokenWithAuthCode,
    fetchTokenWithRfxSecret,
    fetchUserInfo,
    generateAuthorizationLink,
    signOut,
} from '@main/api/resources/oauth';
import {
    type ApiUser,
    updateAvatar,
    type UpdatedApiUser,
    updateUser,
} from '@main/api/resources/users';
import type { Feature } from '@main/domain/features';
import { isFeatureAvailable } from '@main/domain/features';
import { noise } from '@main/utilities/misc';
import { urlSafe } from '@main/utilities/urls';
import base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import { acceptHMRUpdate, defineStore } from 'pinia';

type State = {
    authenticatedScope: string | null;
    authorizationLink: string | null;
    authorizedScopes: string[];
    redirectUrl: string | null;
    state: string | null;
    token: string | null;
    tokenExpiration: Date | null;
    user: ApiUser;
    userInfoPropagated: boolean;
    verifier: string | null;
    pendingSignout: boolean;
    welcomeBackModalActive: boolean;
};

export const useAuthStore = defineStore( 'auth', {
    /**
     * All states which should remain persistent
     * MUST be listed here.
     *
     * @see https://prazdevs.github.io/pinia-plugin-persistedstate/guide/config.html#paths
     */
    persist: {
        paths: [
            'state',
            'verifier',
            'redirectUrl',
            'welcomeBackModalActive',
            'token',
            'tokenExpiration',
            'authorizedScopes',
            'authenticatedScope',
            'user',
        ],
    },

    state(): State {
        return {
            authenticatedScope: 'user',
            authorizationLink: null,
            authorizedScopes: [],
            redirectUrl: null,
            state: '',
            token: null,
            tokenExpiration: null,
            verifier: '',

            user: blankUser(),

            /**
             * Prevents 3rd party plugins from trying
             * to identify the user multiple times.
             */
            userInfoPropagated: false,
            pendingSignout: false,
            welcomeBackModalActive: false,
        };
    },

    actions: {
        async flush() {
            if ( import.meta.env.VITE_ACTIVATE_THIRD_PARTY_PLUGINS !== 'true' ) {
                console.debug( 'No external user identification to be removed' );

                return;
            }

            await invalidateUserIdentity();
            this.user = blankUser();
            this.token = null;
        },

        async signOut() {
            if ( !this.token ) {
                throw new Error( 'Cannot sign out: Not signed in' );
            }

            this.pendingSignout = true;

            try {
                await signOut();
            } catch ( error ) {
                // If the API doesn't recognize our credentials anyway, just
                // ignore the unauthorized error - we're about to flush the
                // store and any existing token with it.
                //
                // We check against HTTP error instead of an API error,
                // because the sign-out uses ky directly, instead of
                // the API client.
                if ( error instanceof Error && !isHttpErrorStatus( error, 401 ) ) {
                    throw error;
                }
            }

            this.userInfoPropagated = false;
        },

        generateAuthorizationLink( redirectUrl?: string ) {
            // See https://auth0.com/docs/protocols/state-parameters
            const state = noise( 40 );

            // See https://auth0.com/docs/flows/authorization-code-flow-with-proof-key-for-code-exchange-pkce
            const verifier = noise( 128 );

            // Generate an SHA256 hash from the challenge and make it URL-safe
            const challenge = urlSafe( sha256( verifier ).toString( base64 ) );

            // Generate the final URL. This relies a lot on global configuration
            // and is thus carried out by the OAuth API module, although not
            // strictly doing an API request.
            const link = generateAuthorizationLink( state, challenge );

            this.state = state;
            this.verifier = verifier;
            this.authorizationLink = link.toString();

            // By checking whether a redirect URL was given, we can choose to
            // preserve the previous redirect URL. So if an error occurred
            // during the token exchange, we can initiate the signin again and
            // be redirected to the old URL if it works this time.
            if ( redirectUrl ) {
                this.redirectUrl = redirectUrl;
            }

            return link;
        },

        async fetchAccessToken( code: string, state: string ) {
            if ( !this.verifier ) {
                throw new Error(
                    'No code verifier available. A token can only be fetched ' +
                        'after an authorization code was requested using the ' +
                        '"generateAuthorizationLink" action.',
                );
            }

            // Check the state matches the previous value
            // See https://auth0.com/docs/protocols/state-parameters
            if ( this.state !== state ) {
                throw new Error( 'Authentication compromised, please sign in again' );
            }

            const { token, expiration, scopes } = await fetchTokenWithAuthCode( code, this.verifier );

            this.token = token;
            this.tokenExpiration = expiration;
            this.authorizedScopes = scopes;
        },

        async fetchRfxAccessToken( secret: string ) {
            const { token, expiration, scopes } = await fetchTokenWithRfxSecret( secret );

            this.token = token;
            this.tokenExpiration = expiration;
            this.authorizedScopes = scopes;
        },

        async fetchUserInfo() {
            this.user = await fetchUserInfo();

            if ( import.meta.env.VITE_ACTIVATE_THIRD_PARTY_PLUGINS !== 'true' ) {
                console.warn(
                    `User with id ${this.user.uuid} would now be identified by 3rd party plugins`,
                );

                return;
            }

            if ( !this.userInfoPropagated ) {
                this.userInfoPropagated = true;
                void propagateUserIdentity( this.user );
            }
        },

        async updateCurrentUserAvatar( user: ApiUser, file: File ) {
            await updateAvatar( user.uuid, file );
            await this.fetchUserInfo();
        },

        async updateUserInfo( data: UpdatedApiUser ) {
            const current = this.user as ApiUser;

            await updateUser( current.uuid, data );

            this.user = { ...current, ...data };
        },
    },

    getters: {
        hasValidToken( { token, tokenExpiration } ) {
            return token !== null && tokenExpiration && new Date( tokenExpiration ) > new Date();
        },

        authenticated( { authenticatedScope, authorizedScopes, token, tokenExpiration } ) {
            return !!(
                token &&
                tokenExpiration &&
                new Date( tokenExpiration ) > new Date() &&
                authorizedScopes.includes( authenticatedScope! )
            );
        },

        hasScope( { authorizedScopes } ) {
            return ( scope: string ) => authorizedScopes.includes( scope );
        },

        hasScopes( { authorizedScopes } ) {
            return ( scopes: string[] ) => {
                return scopes.every( ( scope ) => authorizedScopes.includes( scope ) );
            };
        },

        can( { authorizedScopes } ) {
            return ( ...scopes: string[] ) =>
                authorizedScopes.some( ( scope ) => scopes.includes( scope ) );
        },

        isRfxUser( { authorizedScopes } ) {
            return authorizedScopes.includes( 'rfx' );
        },

        isFeatureEnabled( { user: { experiments } } ) {
            return ( feature: Feature ) => isFeatureAvailable( experiments ?? [], feature );
        },
    },
} );

/**
 * Shorthand type for the auth store: We're using this in the API component as
 * a means of interacting with the authentication data.
 */
export type AuthStore = ReturnType<typeof useAuthStore>;

async function propagateUserIdentity( user: ApiUser ) {
    const [Sentry, { mixpanel }] = await Promise.all( [
        import( '@sentry/browser' ),
        import( '@main/plugins/mixpanel' ),
    ] );

    // Set the user data on Sentry. This allows identifying users with
    // issues while debugging.
    // Note that this is covered under the GDPR: We have a DPA with
    // Sentry, and customers have agreed to us sharing their data with
    // Sentry for this specific purpose.
    Sentry.setUser( {
        email: user.email,
        id: user.uuid,

        /** @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/#ip_address */
        ip_address: '{{auto}}',
        role: user.role,
    } );

    // Identify user via their uuid for assignment in mixpanel.
    // If this is an RFX user, then this ID corresponds to
    // the contact person ID.
    mixpanel.identify( user.uuid );
}

async function invalidateUserIdentity() {
    const [Sentry, { mixpanel }] = await Promise.all( [
        import( '@sentry/browser' ),
        import( '@main/plugins/mixpanel' ),
    ] );

    Sentry.setUser( null );
    mixpanel.reset();
}

const blankUser = (): ApiUser => ( {
    avatarUrl: '',
    createdAt: '',
    email: '',
    emailVerified: false,
    experiments: [],
    facebookConnected: false,
    googleConnected: false,
    isCustomerOwner: false,
    jobTitle: null,
    linkedInConnected: false,
    name: '',
    role: '',
    twitterConnected: false,
    updatedAt: '',
    uuid: '',
} );

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useAuthStore, import.meta.hot ) );
}
