export class OAuthError extends Error {
    public code: string;
    public detail: string | undefined;

    constructor( code: string, message: string, detail?: string ) {
        super( message );
        this.name = 'OAuthError';
        this.stack = this.stack?.split( '\n' ).slice( 1 ).join( '\n' );

        this.code = code;
        this.detail = detail;
    }
}
