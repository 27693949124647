import {
    type ApiCustomer,
    type ApiCustomerContext,
    fetchCustomer,
    updateCustomer,
    type UpdatedApiCustomer,
} from '@main/api/resources/customer';
import type { ApiUser } from '@main/api/resources/users';
import { acceptHMRUpdate, defineStore } from 'pinia';

type State = {
    customer: ApiCustomer;
    availableContexts: ApiCustomerContext[];
    currentContext: ApiCustomerContext;
    users: ApiUser[];
    totalUsers: number;
};

export const useCustomerStore = defineStore( 'customer', {
    persist: true,

    state(): State {
        return {
            availableContexts: [],
            currentContext: {} as ApiCustomerContext,
            customer: {} as ApiCustomer,
            totalUsers: 0,
            users: [],
        };
    },

    actions: {
        flush() {
            //
        },

        async fetchCustomer() {
            this.customer = await fetchCustomer();
        },

        async updateCustomer( customer: UpdatedApiCustomer ) {
            await updateCustomer( customer );
        },
    },
} );

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useCustomerStore, import.meta.hot ) );
}
