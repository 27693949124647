import ApiError from '@main/api/errors/ApiError';
import { clientFactory } from '@main/api/plugin/client';
import { contextFactory } from '@main/api/plugin/context';
import { FriendlyError } from '@main/FriendlyError';
import { useNotificationsStore } from '@main/store/stores/notifications';

function handleError( message: string, error: Error ) {
    const correlationId = error instanceof ApiError ? error.errors.at( 0 )?.id : undefined;
    const friendlyError =
        error instanceof FriendlyError
            ? error
            : new FriendlyError(
                  'Something unexpected happened, and our team has ' +
                      "been notified already. We're sorry for the interruption! " +
                      'In the mean time, you may try again or resume later.',
                  message,
                  error,
              );

    if ( import.meta.env.DEV ) {
        console.error( 'An error occurred', 'originalError' in error ? error.originalError : error );
    }

    useNotificationsStore().notify( {
        detail: `Correlation ID: ${correlationId}`,
        message: friendlyError.fullMessage,
        ttl: 6_000,
        type: 'error',
    } );
}

export const cache = new Map<string, Response>();

// Create the HTTP client. It takes care of caching and auth
export let client = clientFactory( import.meta.env.VITE_API_PREFIX_URL ?? '/api/v0', cache );

// Create the context. This will be passed down to the API resources
export let context = contextFactory( client, cache, handleError );

/**
 * We allow accessing the cache for debugging purposes.
 */
export function getCachedEndpoints() {
    return [...cache.keys()]
        .map( ( url ) => url.split( import.meta.env.VITE_API_URL ).pop() ?? url )
        .sort();
}

/**
 * We allow clearing the cache for debugging purposes.
 */
export function clearCache() {
    cache.clear();
}

// On hot reloads that depend on this module, we want to update the client and
// context instances to reflect the changes.
// This makes it possible to avoid reloading if the API component changes.
if ( import.meta.hot ) {
    import.meta.hot.accept( ( updatedModule ) => {
        if ( updatedModule ) {
            client = updatedModule.client as typeof client;
            context = updatedModule.context as typeof context;
        }
    } );

    import.meta.hot.dispose( () => {
        console.warn( 'API module changed, flushing cache' );
        clearCache();
    } );
}
