export default class ApiMaximumRetriesExceededError extends Error {
    constructor(
        public readonly request: Request,
        public readonly response: Response,
        public readonly attempts: number,
    ) {
        super( 'Maximum number of retry attempts exceeded: Could not get a response in time' );
        this.name = 'ApiMaximumRetriesExceededError';
        this.stack = this.stack?.split( '\n' ).slice( 1 ).join( '\n' );
    }
}
