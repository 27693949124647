import Vue from 'vue';

/**
 * Patches a list by upserting a record.
 * This works by checking whether the list is a thing, the record is a thing,
 * and whether the record lives in the list. To determine the latter, a
 * comparison prop may be passed as the third parameter, which expects the list
 * to contain objects with this prop, so we can do a check for equality.
 *
 * If shouldUnshift is passed as true, the item will be added to the first
 * position in the list. This can be intended e.g. when the list is sorted
 * by date created.
 *
 * @param list
 * @param item
 * @param prop
 * @param shouldUnshift
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function patchList<T extends Record<PropertyKey, any>>(
    list: T[] | undefined,
    item: T | null,
    prop: keyof T = 'id',
    shouldUnshift = false,
): T[] {
    // If we don't have a list yet, initialize one to prevent errors
    if ( !list ) {
        list = [];
    }

    // If the item is null, return right away
    if ( item === null ) {
        return list;
    }

    // If the list is empty, we return early
    if ( list.length === 0 ) {
        list.push( item );

        return list;
    }

    // If the list already includes the exact item, return right away
    if ( list.includes( item ) ) {
        return list;
    }

    // Check if the item exists in the list
    const existingItem = list.find( ( listItem: T ) => listItem[prop] === item[prop] );

    // If we have an existing item, replace it with ours
    if ( existingItem ) {
        Vue.set( list, list.indexOf( existingItem ), item );

        return list;
    }

    // The list doesn't include the item yet, push it
    return shouldUnshift ? [item, ...list] : [...list, item];
}
