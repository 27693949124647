import type { ApiSearchResult } from '@main/api/resources/search';
import { SearchResultCompany } from '@main/domain/search/SearchResultCompany';

/**
 * SearchResult wraps a search result
 * with its included top and additional matches.
 */
export class SearchResult {
    private readonly topMatch: SearchResultCompany;
    private readonly additionalMatches: SearchResultCompany[];

    constructor( private readonly resource: ApiSearchResult ) {
        const projectEntries = resource.projectEntries || [];

        this.topMatch = new SearchResultCompany(
            resource.topMatch,
            projectEntries.find( ( e ) => e.companyUuid === resource.topMatch.uuid ) || null,
            true,
        );

        this.additionalMatches = resource.additionalMatches.map(
            ( c ) =>
                new SearchResultCompany(
                    c,
                    projectEntries.find( ( e ) => e.companyUuid === c.uuid ) || null,
                    false,
                ),
        );
    }

    uuid() {
        return this.resource.uuid;
    }

    score() {
        return this.resource.aggregatedScore;
    }

    matchedKeywords() {
        return this.resource.aggregatedMatchedKeywords;
    }

    company() {
        return this.topMatch;
    }

    canBeExcluded() {
        if ( this.company().hasPhase() ) {
            return false;
        }

        return this.subsidiaries().filter( ( company ) => company.hasPhase() ).length === 0;
    }

    isVisible() {
        const entry = this.topMatch.projectEntry();

        if ( !entry ) {
            return true;
        }

        return !entry.isExcluded();
    }

    hasSubsidiaries() {
        return this.additionalMatches.length > 0;
    }

    subsidiaries() {
        return this.additionalMatches;
    }

    hasTopMatchUrl() {
        return !!this.resource.topMatch.url;
    }
}
