import ApiVersionError from '@main/api/errors/ApiVersionError';
import { flushAll as flushAllPersistentStores } from '@main/store/persistentStorage';
import { useAuthStore } from '@main/store/stores/auth';
import { useNotificationsStore } from '@main/store/stores/notifications';

export const clientVersion = import.meta.env.VITE_APP_VERSION?.substring( 0, 7 );

export async function matchClientVersion( serverVersion: string ) {
    if ( isClientUpToDate( serverVersion ) ) {
        return;
    }

    const error = new ApiVersionError( serverVersion, clientVersion );
    const detail = [
        'Reloading application ...',
        'Please refresh the page to get the latest version ' +
            'if your application does not reload automatically.',
    ];

    if ( useAuthStore().isFeatureEnabled( 'debug' ) ) {
        detail.push( error.message );
    }

    useNotificationsStore().notify( {
        message: 'New version available',
        detail,
        type: 'info',
        persistent: true,
    } );

    // Flush all stores so newer store versions can be initialized properly.
    await flushAllPersistentStores();

    // Reload the application.
    reload();

    // Reload should happen soon. Meanwhile, interrupt the chain of execution by
    // throwing a meaningful exception.
    throw error;
}

function reload() {
    setTimeout( () => {
        window.location.reload();
    }, 5000 );
}

function isClientUpToDate( currentVersion: string ) {
    return currentVersion.substring( 0, 7 ) === clientVersion;
}
