import type { Feature } from '@main/domain/features';
import type { AuthStore } from '@main/store/stores/auth';
import { useAuthStore } from '@main/store/stores/auth';
import type { PluginFunction } from 'vue';

export const capabilitiesPlugin: PluginFunction<unknown> = function capabilitiesPlugin( Vue_ ) {
    let authStore: AuthStore;

    // noinspection UnnecessaryLocalVariableJS
    const can: CapabilityCheck = function can( ...scopes: string[] ) {
        const store = !authStore ? ( authStore = useAuthStore() ) : authStore;

        return store.hasScopes( scopes );
    };

    const hasFeature: FeatureCheck = function hasFeature( feature: Feature ) {
        const store = !authStore ? ( authStore = useAuthStore() ) : authStore;

        return store.isFeatureEnabled( feature );
    };

    Vue_.prototype.$can = can;
    Vue_.prototype.$hasFeature = hasFeature;
};

export type CapabilityCheck = ( ...scopes: string[] ) => boolean;

export type FeatureCheck = ( feature: Feature ) => boolean;

export function isDevToolsAvailable() {
    // Dev Tools refers to the tools that enable matchory employees
    // to browse and check the ES index.
    // These tools are currently implemented in the backend,
    // but will need to find a better home.
    return import.meta.env.VITE_DEV_TOOLS_AVAILABLE === 'true';
}
