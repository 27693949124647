import { persistentStorage } from '@main/store/persistentStorage';
import type { PiniaPlugin, PiniaPluginContext } from 'pinia';
import { markRaw } from 'vue';

/**
 * Pinia Persistent Storage Plugin
 * ===============================
 * Makes methods available in all stores as `this.persistentStorage`,
 * that act on all persistent stores.
 */
export const persistentStoragePlugin = function persistentStoragePlugin(
    context: PiniaPluginContext,
) {
    if ( context.options.persist ) {
        persistentStorage.registerStore( context.store );
    }

    // The markRaw call seems necessary following Pinia docs.
    // See: https://pinia.vuejs.org/core-concepts/plugins.html#adding-new-external-properties
    return { persistentStorage: markRaw( persistentStorage ) };
} satisfies PiniaPlugin;
