import { OAuthError } from '@main/api/errors/OAuthError';
import { flushAll as flushAllPersistentStores } from '@main/store/persistentStorage';
import { useAuthStore } from '@main/store/stores/auth';
import type { Location, Route } from 'vue-router';

/**
 * In-flight token request, or null if none
 */
let pending: Promise<unknown> | null = null;

/**
 * Authenticates a request by injecting the access token.
 * If no access token exists yet, it will be fetched.
 *
 * @param request
 */
export async function authenticate( request: Request ) {
    // Make sure to let token requests through: We'd land in an infinite
    // loop otherwise.
    if ( request.url.endsWith( import.meta.env.VITE_AUTH_TOKEN_ENDPOINT ) ) {
        return request;
    }

    // If there's another pending auth request, wait for it to finish. That
    // way, we'll never fetch more than one token for parallel requests.
    if ( pending ) {
        await pending;
    }

    const token = await getToken();

    if ( token ) {
        request.headers.set( 'Authorization', `Bearer ${token}` );
    }

    return request;
}

export async function authorize( route: Route ) {
    const store = useAuthStore();

    // Read authorization code and state from the query
    const { code, state, error, error_description, hint } = route.query;

    if ( error ) {
        throw new OAuthError(
            error as string,
            error_description as string,
            hint as string | undefined,
        );
    }

    // If we're lacking the code or state here, something went wrong. We
    // redirect to the signin page so the user may authorize again.
    if ( !code || !state ) {
        await startSignIn();

        return;
    }

    // Attempt to obtain an access token using the given code and state
    // values. This will perform regular OAuth authorization.
    try {
        pending = store.fetchAccessToken( code as string, state as string );
        await pending;
    } catch ( error ) {
        // Redirect to the signin page. Note: This might result in an
        // infinite loop. We should probably implement a cool-down
        // mechanism here.
        await startSignIn();

        return;
    }

    const redirectUrl = store.redirectUrl;

    if ( redirectUrl ) {
        return { path: redirectUrl } satisfies Location;
    }

    // Fallback to the dashboard if we don't have a previous URL
    return { name: import.meta.env.VITE_ROUTE_HOME } satisfies Location;
}

export async function startSignIn( redirectUrl?: string ) {
    const store = useAuthStore();

    if ( store.pendingSignout ) {
        throw new Error( 'Sign out is pending' );
    }

    const { href, protocol } = window.location;
    redirectUrl =
        redirectUrl ||
        href
            .replace( `${protocol}//`, '' )
            .split( /\/(.*)/s )
            .pop()!;

    const [, authorizationLink] = await Promise.all( [
        flushAllPersistentStores(),
        store.generateAuthorizationLink( redirectUrl ),
    ] );

    // Redirect to the login page
    window.location.assign( authorizationLink.toString() );
}

export async function logout() {
    await flushAllPersistentStores();

    // Redirect the user to the logout endpoint
    window.location.assign( import.meta.env.VITE_AUTH_SIGN_OUT_ENDPOINT );
}

/**
 * Retrieves the current access token. If none exists, creates one.
 *
 * @private
 */
async function getToken() {
    const store = useAuthStore();

    // Check whether we have an existing token
    if ( !store.hasValidToken ) {
        // Wait for the promise to resolve, so we will have a valid token at
        // hand once it resolves. Note that if _pending is null, this will
        // resolve immediately, and the access token will still be null!
        // That's okay though: The unauthorized handler will catch that.
        await pending;
    }

    // Now that we're sure we have a token, return it from the store
    return store.token;
}
