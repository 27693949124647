export type Experiment = 'debug' | 'rfi' | 'conversations' | 'search';

export type Feature =
    | 'debug'
    | 'rfi'
    | 'conversations'
    | 'contacts'
    | 'required-keywords'
    | 'aggregated-keywords';

export function isFeatureAvailable( experiments: Experiment[], feature: Feature ) {
    switch ( feature ) {
        case 'debug':
            return experiments.includes( 'debug' );
        case 'rfi':
            return experiments.includes( 'rfi' );
        case 'conversations':
            return experiments.includes( 'conversations' ) || experiments.includes( 'rfi' );
        case 'contacts':
            return experiments.includes( 'conversations' ) || experiments.includes( 'rfi' );
        case 'required-keywords':
            return experiments.includes( 'search' );
        case 'aggregated-keywords':
            return experiments.includes( 'search' );
    }

    return false;
}
