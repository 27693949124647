/**
 * Wrapper that represents timeouts of our API.
 *
 * There is a ky TimeoutError class, but we get
 * an instance of Error with TimeoutError as name.
 *
 * We exchange it for an instance of this class,
 * so that the API-calling function can check the
 * instance type to identify timeouts.
 */
export default class ApiTimeoutError extends Error {
    constructor() {
        super( 'API call timed out' );
        this.name = 'ApiTimeoutError';
        this.stack = this.stack?.split( '\n' ).slice( 1 ).join( '\n' );
    }
}
