import { is409ApiError } from '@main/api/errors/ApiError';
import ApiMaximumRetriesExceededError from '@main/api/errors/ApiMaximumRetriesExceededError';
import ApiTimeoutError from '@main/api/errors/ApiTimeoutError';
import { maxKeywordsPerCondition } from '@main/api/limits';
import type { ListOptions } from '@main/api/plugin/types/api';
import type { DebugFetchResults } from '@main/api/resources/debug';
import type { ApiProjectEntry } from '@main/api/resources/projects';
import type { ApiSearchResultsStage } from '@main/api/resources/search';
import {
    type AggregatableField,
    type ApiSearchQuery,
    ApiSearchQueryDebugAggregations,
    ApiSearchQueryDefaultAggregations,
    type ApiSearchResult,
    type CountedAggregation,
    createQuery,
    explainQuery,
    type FetchApiSearchResultsReturn,
    fetchQueries,
    fetchQuery,
    fetchResults,
    type NewApiSearchQuery,
    type SearchCondition,
    type SearchConditionKeyword,
    type SearchConditionType,
    type SearchFilter,
    type SearchResultsAugmentationMeta,
    updateDefaultQuery,
} from '@main/api/resources/search';
import type {
    AddFilterEvent,
    RemoveFilterEvent,
} from '@main/components/App/Projects/Features/ProjectSearch.vue';
import { SearchQuery } from '@main/domain/search/SearchQuery';
import { SearchResult } from '@main/domain/search/SearchResult';
import { useDebugStore } from '@main/store/stores/debug';
import { useNotificationsStore } from '@main/store/stores/notifications';
import { useTrackingStore } from '@main/store/stores/tracking';
import { patchList } from '@main/utilities/store';
import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue from 'vue';

const MAX_STORED_QUERIES = 20;

export const RESULTS_PER_PAGE = 25;

// SearchResultsStageState represents the flow of stages:
// --------------------------------------------------------------
//
// 1) loading-initial:
// Initial results are loading.
//
// 2) loading-intermediate:
// Intermediate results are loading.
//
// 3) loading-final:
// Final results are loading.
//
// 4) done-final:
// Final results are completely done.
//
// 5a) loading-additional:
// Additional results are loading, after final results were loaded, but were not completed.
// We make the final results available to the user, but fetch additional ones in the background.
// Once we have additional results, we offer the user to update the results list.
//
// 5b) done-additional:
// Additional results are completely done or timed out eventually.
//
// 5c) accepted-additional:
// The additional results were offered to the user, and they accepted them.
//
// 5d) declined-additional:
// The additional results were offered to the user, but declined.
//
// --------------------------------------------------------------
// Alternatively, we have:
// --------------------------------------------------------------
//
// 6a) loading-at-once:
// 6b) done-at-once
// All results are loaded at once.None of the above stages applies.
//
// 7) cancelled:
// The user cancelled any of the above stages.
export type SearchResultsStageState =
    | 'loading-initial'
    | 'loading-intermediate'
    | 'loading-final'
    | 'done-final'
    | 'loading-additional'
    | 'done-additional'
    | 'accepted-additional'
    | 'declined-additional'
    | 'loading-at-once'
    | 'done-at-once'
    | 'cancelled';

const stageToState: Record<ApiSearchResultsStage, SearchResultsStageState> = {
    initial: 'loading-initial',
    intermediate: 'loading-intermediate',
    final: 'loading-final',
    'at-once': 'loading-at-once',
    additional: 'loading-additional',
};

const stateToStage: Record<SearchResultsStageState, ApiSearchResultsStage | null> = {
    'loading-initial': 'initial',
    'loading-intermediate': 'intermediate',
    'loading-final': 'final',
    'done-final': 'final',
    'loading-additional': 'additional',
    'done-additional': 'additional',
    'accepted-additional': 'additional',
    'declined-additional': 'additional',
    'loading-at-once': 'at-once',
    'done-at-once': 'at-once',
    cancelled: null,
};

type ResultMeta = {
    totalResults: number;
    availableResults: number;
    availableCompanies: number;
    aggregations: Partial<Record<AggregatableField, CountedAggregation[]>>;
    augmentations: SearchResultsAugmentationMeta;
};

type State = {
    // Queries per project UUID
    // ----------------------------------------
    queries: Record<string, ApiSearchQuery[]>;
    defaultQueries: Record<string, ApiSearchQuery>;
    activeQueryUuids: Record<string, string | null>;

    // List of previously performed queries
    historicalQueries: Record<string, ApiSearchQuery[]>;

    // Query which reflects active query plus all live changes made
    // by the user until a new search is triggered.
    pendingQuery: Record<string, NewApiSearchQuery | null>;

    // Results per query UUID
    // ----------------------------------------

    // The state of the staged loading process.
    stagesState: Record<string, SearchResultsStageState>;

    // The loaded search results per query UUID and stage.
    results: Record<string, Record<ApiSearchResultsStage, ApiSearchResult[]>>;

    // The results taken from the loaded results and delivered to the user, per query UUID.
    deliveredResults: Record<string, ApiSearchResult[]>;

    // The stage to which the most recently delivered results belong, per query UUID.
    deliveredStages: Record<string, ApiSearchResultsStage>;

    // True, if results are currently being delivered for the given query UUID.
    isDelivering: Record<string, boolean>;

    // Meta information per query UUID and stage, such as counts and aggregations.
    metas: Record<string, Record<ApiSearchResultsStage, ResultMeta>>;

    // Other
    // ----------------------------------------

    // Debug data for search results that was used last.
    lastDebug: {
        projectUuid: string;
        queryUuid: string;
        data: DebugFetchResults;
    } | null;
};

export const useSearchStore = defineStore( 'search', {
    state(): State {
        return {
            // Queries
            queries: {},
            activeQueryUuids: {},
            defaultQueries: {},
            historicalQueries: {},
            pendingQuery: {},

            // Results
            stagesState: {},
            results: {},
            deliveredResults: {},
            deliveredStages: {},
            isDelivering: {},
            metas: {},

            // Debug
            lastDebug: null,
        };
    },

    actions: {
        // region Queries

        async createQuery(
            query: NewApiSearchQuery,
            projectUuid: string,
            previousQueryUuid?: string,
        ) {
            if ( !query.aggregations || query.aggregations.length === 0 ) {
                query = enrichQueryWithDefaultAggregations( query );
            }

            /**
             * TODO: Remove this block when the taxonomy filters are activated.
             * (Requires new Index to be shipped to production)
             */
            if ( import.meta.env.VITE_ACTIVATE_TAXONOMY_FILTERS === 'true' ) {
                query = enrichQueryWithDebugAggregations( query );
            }

            const resource = await createQuery(
                {
                    ...query,
                    previousQueryUuid,
                },
                projectUuid,
            );

            if ( !resource ) {
                throw new Error( 'Failed to create query' );
            }

            this.$patch( ( state ) => limitStoredQueries( state, projectUuid ) );
            this.$patch( ( state ) => mutateQuery( state, true, resource, projectUuid ) );
            this.$patch( ( state ) =>
                patchList( state.historicalQueries[projectUuid], resource, 'uuid', true ),
            );

            void useTrackingStore().sendSearchTriggered( resource.uuid );

            return resource.uuid;
        },

        async fetchQuery( queryUuid: string, projectUuid: string, makeActive = false ) {
            const query = await fetchQuery( queryUuid, projectUuid );

            if ( !query ) {
                throw new Error( 'Failed to load query' );
            }

            this.$patch( ( state ) => mutateQuery( state, makeActive, query, projectUuid ) );
        },

        async fetchDefaultQuery( projectUuid: string, makeActive = false ) {
            const query = await fetchQuery( 'default', projectUuid );

            if ( !query ) {
                throw new Error( 'Failed to load query' );
            }

            Vue.set( this.defaultQueries, projectUuid, query );

            this.$patch( ( state ) => mutateQuery( state, makeActive, query, projectUuid ) );

            return query;
        },

        async updateDefaultQuery( projectUuid: string, queryUuid: string ) {
            await updateDefaultQuery( projectUuid, queryUuid );

            const query = this.queries[projectUuid]?.find( ( { uuid } ) => uuid === queryUuid );

            if ( !query ) {
                throw new Error(
                    'Unexpected state: Data for the new default ' +
                        'query is missing from the store. Make sure to fetch ' +
                        'the query before updating the default.',
                );
            }

            Vue.set( this.defaultQueries, projectUuid, query );
        },

        async fetchQueries( projectUuid: string, options?: ListOptions<ApiSearchQuery> ) {
            const queries = await fetchQueries( projectUuid, options );

            if ( !queries ) {
                throw new Error( 'Failed to fetch queries' );
            }

            Vue.set( this.historicalQueries, projectUuid, queries );
        },

        async explainLastDebugQuery( companyUuid: string ) {
            if ( !this.lastDebug ) {
                return null;
            }

            return explainQuery(
                this.lastDebug.projectUuid,
                this.lastDebug.queryUuid,
                companyUuid,
                this.lastDebug.data,
            );
        },

        async resetActiveQuery( projectUuid: string ) {
            const previousQueryUuid = this.activeQueryUuids[projectUuid];

            if ( previousQueryUuid ) {
                void useTrackingStore().sendSearchReset( previousQueryUuid );
            }

            await this.fetchDefaultQuery( projectUuid, true );
        },

        // endregion

        // region Pending query
        setPendingQuery( query: SearchQuery ) {
            Vue.set( this.pendingQuery, query.uuid(), query.mutableProperties() );
        },

        resetPendingQuery() {
            this.pendingQuery = {};
        },

        setPendingQueryCondition(
            queryUuid: string,
            conditionType: SearchConditionType,
            keywords: SearchConditionKeyword[],
        ) {
            if ( !this.pendingQuery[queryUuid] ) {
                throw new Error( `Unexpected state: pending query is not
                available for base query with UUID ${queryUuid}.` );
            }

            let newConditions = [...( this.pendingQuery[queryUuid].conditions || [] )];

            switch ( conditionType ) {
                case 'keyword-filter':
                    newConditions = this.addRequiredCondition( keywords, newConditions );
                    break;

                case 'product':
                    newConditions = this.addProductCondition( queryUuid, keywords, newConditions );
                    break;

                case 'context':
                    newConditions = this.addContextCondition( queryUuid, keywords, newConditions );
                    break;
            }

            // Remove conditions without any keywords to avoid errors.
            newConditions = newConditions.filter( ( c ) => c.keywords.length > 0 );

            Vue.set( this.pendingQuery[queryUuid], 'conditions', newConditions );
        },

        /**
         * If a required condition is passed, just replace it, as it does not
         * affect product or context conditions.
         */
        addRequiredCondition( keywords: SearchConditionKeyword[], newConditions: SearchCondition[] ) {
            newConditions = newConditions.filter( ( { type } ) => type !== 'keyword-filter' );

            newConditions.push( {
                keywords: keywords.slice( 0, maxKeywordsPerCondition ),
                required: true,
                type: 'keyword-filter',
            } );

            return newConditions;
        },

        addProductCondition(
            queryUuid: string,
            keywords: SearchConditionKeyword[],
            newConditions: SearchCondition[],
        ) {
            newConditions = newConditions.filter( ( { type } ) => type !== 'product' );
            const productKeywords = keywords;
            const contextKeywords = this.getPendingQueryConditionKeywords( queryUuid, 'context' );

            // Always set the first product keyword required.
            if ( productKeywords.length > 0 ) {
                newConditions = this.addRequiredCondition( [productKeywords[0]], newConditions );
            }

            newConditions.push( {
                keywords: productKeywords.slice( 0, maxKeywordsPerCondition ),
                required: 1,
                type: 'product',
            } );

            newConditions = deduplicateContextCondition(
                productKeywords,
                contextKeywords,
                newConditions,
            );

            return newConditions;
        },

        addContextCondition(
            queryUuid: string,
            keywords: SearchConditionKeyword[],
            newConditions: SearchCondition[],
        ) {
            newConditions = newConditions.filter( ( { type } ) => type !== 'context' );
            const contextKeywords = keywords;
            const productKeywords = this.getPendingQueryConditionKeywords( queryUuid, 'product' );

            newConditions.push( {
                keywords: contextKeywords.slice( 0, maxKeywordsPerCondition ),
                required: 0,
                type: 'context',
            } );

            newConditions = deduplicateContextCondition(
                productKeywords,
                contextKeywords,
                newConditions,
            );

            return newConditions;
        },

        setPendingQueryFilter( queryUuid: string, { filter }: AddFilterEvent ) {
            if ( !this.pendingQuery[queryUuid] ) {
                throw new Error( `Unexpected state: pending query is not
                available for base query with UUID ${queryUuid}.` );
            }

            // Prevent multiple geographical filters from interfering with each other.
            const filters =
                ( ['geoRegion', 'geoBoundingBox'].includes( filter.field )
                    ? this.pendingQuery[queryUuid].filters?.filter(
                          ( { field } ) => !['geoRegion', 'geoBoundingBox'].includes( field ),
                      )
                    : this.pendingQuery[queryUuid].filters?.filter(
                          ( { field } ) => field !== filter.field,
                      ) ) ?? [];

            Vue.set( this.pendingQuery[queryUuid], 'filters', [...filters, filter] );
        },

        removePendingQueryFilter( queryUuid: string, { filter, group }: RemoveFilterEvent ) {
            if ( !this.pendingQuery[queryUuid] ) {
                throw new Error( `Unexpected state: pending query is not
                available for base query with UUID ${queryUuid}.` );
            }

            // If the removal of a geoRegion filter is requested, check by the
            // passed group parameter, which part of it is to be removed.
            const filters =
                ( filter.field === 'geoRegion'
                    ? this.pendingQuery[queryUuid].filters
                          ?.map( ( activeFilter ) => {
                              if ( activeFilter.field === 'geoRegion' && group ) {
                                  delete ( activeFilter as SearchFilter<'geoRegion'> ).value[group];
                              }

                              return activeFilter;
                          } )
                          .filter(
                              ( activeFilter ) =>
                                  activeFilter.field !== 'geoRegion' ||
                                  Object.keys( ( activeFilter as SearchFilter<'geoRegion'> ).value )
                                      .length > 0,
                          )
                    : this.pendingQuery[queryUuid].filters?.filter(
                          ( activeFilter ) => activeFilter.field !== filter.field,
                      ) ) ?? [];

            Vue.set( this.pendingQuery[queryUuid], 'filters', filters );
        },

        // endregion

        // region Results

        fetchResults(
            queryUuid: string,
            projectUuid: string,
            debug: DebugFetchResults | null = null,
        ): void {
            this.$patch( ( state ) => resetResults( state, queryUuid ) );

            const debugStore = useDebugStore();
            const stage = debugStore.isDebugQueryEnabled ? 'at-once' : 'initial';

            fetchResultsStage( this, queryUuid, projectUuid, debug, stage );
        },

        acceptAdditionalResults( queryUuid: string ) {
            Vue.set( this.stagesState, queryUuid, 'accepted-additional' );
            deliverAdditionalResults( this, queryUuid );
        },

        declineAdditionalResults( queryUuid: string ) {
            Vue.set( this.stagesState, queryUuid, 'declined-additional' );
        },

        cancelResults( queryUuid: string ): void {
            Vue.set( this.stagesState, queryUuid, 'cancelled' );
        },

        // endregion

        // region Entries
        patchProjectEntry( projectUuid: string, entry: ApiProjectEntry ) {
            patchResults( this, projectUuid, entry );
        },

        // endregion
    },

    getters: {
        // region Queries

        getActiveQuery( { activeQueryUuids, queries } ) {
            return ( projectUuid: string ) => {
                const queriesPerUuid = Object.fromEntries(
                    Object.entries( activeQueryUuids )
                        .map(
                            ( [key, value] ) =>
                                [key, queries[key].find( ( { uuid } ) => uuid === value )] as const,
                        )
                        .filter( ( entry ): entry is [string, ApiSearchQuery] => !!entry[1] ),
                );

                return projectUuid in queriesPerUuid
                    ? new SearchQuery( queriesPerUuid[projectUuid] )
                    : null;
            };
        },

        getQueries( { historicalQueries } ) {
            return ( projectUuid: string ) =>
                ( historicalQueries[projectUuid] ?? [] ).map( ( q ) => new SearchQuery( q ) );
        },

        getQuery() {
            return ( projectUuid: string, queryUuid: string ) =>
                this.getQueries( projectUuid ).find( ( query ) => query.uuid() === queryUuid );
        },

        isDefaultQuery( { defaultQueries } ): ( projectUuid: string, queryUuid: string ) => boolean {
            return ( projectUuid: string, queryUuid: string ) =>
                defaultQueries[projectUuid]?.uuid === queryUuid;
        },

        // endregion

        // region Pending query
        /**
         * Get the pending query's resource payload, when the user triggers a
         * new search, and a new query is to be created.
         */
        getPendingQueryPayload( { pendingQuery } ) {
            return ( queryUuid: string ) => pendingQuery[queryUuid] ?? null;
        },

        /**
         * Get the pending query's domain object, when access is needed to the
         * domain object's methods.
         */
        getPendingQuery( { pendingQuery } ) {
            return ( queryUuid: string ) => {
                const query = pendingQuery[queryUuid];

                if ( !query ) {
                    return null;
                }

                return new SearchQuery( {
                    createdAt: new Date().toISOString(),
                    deletedAt: '',
                    updatedAt: new Date().toISOString(),
                    uuid: 'candidate',

                    aggregations: query.aggregations ?? [],
                    conditions: query.conditions ?? [],
                    filters: query.filters ?? [],
                    previousQueryUuid: query.previousQueryUuid ?? null,
                } );
            };
        },

        getPendingQueryConditionKeywords( { pendingQuery } ) {
            return ( queryUuid: string, condition: SearchConditionType ) => {
                if ( !pendingQuery[queryUuid] ) {
                    return [];
                }

                return (
                    pendingQuery[queryUuid].conditions?.find( ( { type } ) => type === condition )
                        ?.keywords ?? []
                );
            };
        },

        // endregion

        // region Query results

        isLoadingStage( { stagesState } ): ( queryUuid: string ) => boolean {
            return ( queryUuid: string ) =>
                stagesState[queryUuid] === 'loading-initial' ||
                stagesState[queryUuid] === 'loading-intermediate' ||
                stagesState[queryUuid] === 'loading-final' ||
                stagesState[queryUuid] === 'loading-additional' ||
                stagesState[queryUuid] === 'loading-at-once';
        },

        isLoadingRegularStage(): ( queryUuid: string ) => boolean {
            return ( queryUuid: string ) =>
                !this.isLoadingAdditionalStage( queryUuid ) && this.isLoadingStage( queryUuid );
        },

        isLoadingAdditionalStage( { stagesState } ): ( queryUuid: string ) => boolean {
            return ( queryUuid: string ) => stagesState[queryUuid] === 'loading-additional';
        },

        getIsDeliveringStage( { isDelivering } ): ( queryUuid: string ) => boolean {
            return ( queryUuid: string ) => isDelivering[queryUuid] ?? false;
        },

        getStageState( { stagesState } ): ( queryUuid: string ) => SearchResultsStageState | null {
            return ( queryUuid: string ) => stagesState[queryUuid] ?? null;
        },

        getDeliveredStage( {
            deliveredStages,
        } ): ( queryUuid: string ) => ApiSearchResultsStage | null {
            return ( queryUuid: string ) => deliveredStages[queryUuid] ?? null;
        },

        hasPendingResults( { stagesState } ): ( queryUuid: string ) => boolean {
            // As long as we are not done or have started fetching additional results,
            // we want to show the results already available as pending.
            return ( queryUuid: string ) =>
                stagesState[queryUuid] === 'loading-initial' ||
                stagesState[queryUuid] === 'loading-intermediate' ||
                stagesState[queryUuid] === 'loading-final';
        },

        /**
         * True while we are loading additional results.
         */
        hasPendingAdditionalResults( { stagesState } ): ( queryUuid: string ) => boolean {
            // As long as we are not done or have started fetching additional results,
            // we want to show the results as pending.
            return ( queryUuid: string ) => stagesState[queryUuid] === 'loading-additional';
        },

        /**
         * True if additional results are available.
         */
        hasAdditionalResults(): ( queryUuid: string ) => boolean {
            return ( queryUuid: string ) => this.getAdditionalResults( queryUuid ).length > 0;
        },

        /**
         * True if additional results were offered to the user and they accepted them.
         */
        hasAcceptedAdditionalResults( { stagesState } ): ( queryUuid: string ) => boolean {
            return ( queryUuid: string ) => stagesState[queryUuid] === 'accepted-additional';
        },

        hasDeclinedAdditionalResults( { stagesState } ): ( queryUuid: string ) => boolean {
            return ( queryUuid: string ) => stagesState[queryUuid] === 'declined-additional';
        },

        getDeliveredResults( {
            stagesState,
            deliveredResults,
        } ): ( queryUuid: string ) => SearchResult[] {
            return ( queryUuid: string ) => {
                if ( !stagesState[queryUuid] || stagesState[queryUuid] === 'cancelled' ) {
                    return [];
                }

                const results = ( deliveredResults[queryUuid] || [] ).map(
                    ( result ) => new SearchResult( result ),
                );

                if ( this.hasPendingResults( queryUuid ) ) {
                    return results.slice( 0, RESULTS_PER_PAGE );
                }

                return results;
            };
        },

        getFinalResults( { stagesState, results } ): ( queryUuid: string ) => SearchResult[] {
            return ( queryUuid: string ) => {
                if ( !stagesState[queryUuid] || stagesState[queryUuid] === 'cancelled' ) {
                    return [];
                }

                if ( !results[queryUuid] ) {
                    return [];
                }

                if ( results[queryUuid]['at-once'] ) {
                    return ( results[queryUuid]['at-once'] ?? [] )
                        .flat()
                        .map( ( result ) => new SearchResult( result ) );
                }

                return ( results[queryUuid]['final'] ?? [] )
                    .flat()
                    .map( ( result ) => new SearchResult( result ) );
            };
        },

        getAdditionalResults( { stagesState, results } ): ( queryUuid: string ) => SearchResult[] {
            return ( queryUuid: string ): SearchResult[] => {
                if (
                    stagesState[queryUuid] !== 'done-additional' &&
                    stagesState[queryUuid] !== 'accepted-additional'
                ) {
                    return [];
                }

                // Check whether we have new results different from the final ones.
                const finalResults = this.getFinalResults( queryUuid );

                const additionalResults = (
                    ( results[queryUuid]['additional'] ?? [] ) as ApiSearchResult[]
                )
                    .flat()
                    .map( ( result ) => new SearchResult( result ) );

                if ( finalResults.length !== additionalResults.length ) {
                    return additionalResults;
                }

                finalResults.forEach( ( result, index ) => {
                    if ( result.uuid !== additionalResults[index].uuid ) {
                        return additionalResults;
                    }
                } );

                // Final results and additional ones are identical.
                return [];
            };
        },

        getMeta( { metas } ) {
            return ( queryUuid: string ): ResultMeta | null => {
                const meta = metas[queryUuid] ?? null;

                if ( !meta ) {
                    return null;
                }

                const stage = this.getDeliveredStage( queryUuid );

                if ( !stage ) {
                    return null;
                }

                return meta[stage] ?? null;
            };
        },

        getAggregations(): (
            queryUuid: string,
        ) => Partial<Record<AggregatableField, CountedAggregation[]>> {
            return ( queryUuid: string ) => {
                const meta = this.getMeta( queryUuid );

                if ( !meta ) {
                    return {};
                }

                return meta.aggregations;
            };
        },

        getAugmentations(): ( queryUuid: string ) => SearchResultsAugmentationMeta | null {
            return ( queryUuid: string ) => {
                const meta = this.getMeta( queryUuid );

                if ( !meta ) {
                    return null;
                }

                return meta.augmentations;
            };
        },

        getAvailableResultsCount(): ( queryUuid: string ) => number {
            return ( queryUuid: string ) => {
                const meta = this.getMeta( queryUuid );

                if ( !meta ) {
                    return 0;
                }

                return meta.availableResults;
            };
        },

        getAvailableResultCompaniesCount(): ( queryUuid: string ) => number {
            return ( queryUuid: string ) => {
                const meta = this.getMeta( queryUuid );

                if ( !meta ) {
                    return 0;
                }

                return meta.availableCompanies;
            };
        },

        getTotalMatchesCount(): ( queryUuid: string ) => number {
            return ( queryUuid: string ) => {
                const meta = this.getMeta( queryUuid );

                if ( !meta ) {
                    return 0;
                }

                return meta.totalResults;
            };
        },

        getStagedTotalMatchesCount(): ( queryUuid: string ) => number {
            return ( queryUuid: string ) => {
                const stage = this.getDeliveredStage( queryUuid );

                if ( !stage ) {
                    return 0;
                }

                if ( stage === 'initial' || stage === 'intermediate' ) {
                    return this.deliveredResults[queryUuid]?.length ?? 0;
                }

                return this.getTotalMatchesCount( queryUuid );
            };
        },

        // endregion
    },
} );

function mutateQuery(
    state: State,
    makeActiveQuery: boolean,
    query: ApiSearchQuery,
    projectUuid: string,
) {
    Vue.set( state.queries, projectUuid, patchList( state.queries[projectUuid], query, 'uuid' ) );

    if ( makeActiveQuery ) {
        mutateLastQuery( state, query.uuid, projectUuid );
    }
}

function mutateLastQuery( state: State, queryUuid: string | null, projectUuid: string ) {
    Vue.set( state.activeQueryUuids, projectUuid, queryUuid );
}

function enrichQueryWithDefaultAggregations( query: NewApiSearchQuery ) {
    return {
        ...query,
        aggregations: ApiSearchQueryDefaultAggregations,
    };
}

function enrichQueryWithDebugAggregations( query: NewApiSearchQuery ) {
    if ( query.aggregations?.some( ( a ) => ApiSearchQueryDebugAggregations.includes( a ) ) ) {
        return query;
    }

    return {
        ...query,
        aggregations: query.aggregations?.concat( ApiSearchQueryDebugAggregations ),
    };
}

async function fetchResultsStage(
    state: State,
    queryUuid: string,
    projectUuid: string,
    debug: DebugFetchResults | null,
    stage: ApiSearchResultsStage,
): Promise<void> {
    // Drop everything we are doing when we got cancelled meanwhile.
    if ( state.stagesState[queryUuid] === 'cancelled' ) {
        return;
    }

    // Set the stage that is now being loaded.
    Vue.set( state.stagesState, queryUuid, stageToState[stage] );

    //
    // Load the stage.
    //

    let results: FetchApiSearchResultsReturn | null = null;

    try {
        results = await fetchResults( queryUuid, projectUuid, stage, debug );
    } catch ( error ) {
        const notificationsStore = useNotificationsStore();

        if ( error instanceof ApiMaximumRetriesExceededError ) {
            notificationsStore.notify( {
                detail: [
                    'Please try again later.',
                    'Looks like we were not able to fetch the results for you right now.',
                ],
                message: 'Results not available',
                persistent: true,
            } );
        } else if ( error instanceof ApiTimeoutError ) {
            notificationsStore.notify( {
                detail: [
                    'Please try again later.',
                    'Or simplify your query by removing keywords to help us process it faster.',
                ],
                message: 'Oops. This search is taking a lot of time.',
                persistent: true,
            } );
        } else if ( is409ApiError( error ) ) {
            notificationsStore.notify( {
                detail: [
                    'Please try again later.',
                    'If this problem persists, please let us know.',
                ],
                message: 'Hm. Something seems wrong with your search request.',
                persistent: true,
            } );
        } else {
            throw error;
        }
    }

    if ( !results ) {
        throw new Error( 'Unexpected failure when loading results.' );
    }

    //
    // API request succeeded.
    //

    // Drop everything we are doing when we got cancelled while we requested the results.
    const currentState = state.stagesState[queryUuid] as SearchResultsStageState;

    if ( currentState === 'cancelled' ) {
        return;
    }

    // Let's see whether we need to retry the current stage.
    // This happens when we are fetching additional results or all at-once,
    // and some of the augmentations are not available yet
    // and have not yet exceeded the maximum waiting time.
    const retryStage = results.retryStage;

    if ( retryStage ) {
        window.setTimeout( () => {
            fetchResultsStage( state, queryUuid, projectUuid, debug, stage );
        }, 2000 );

        return;
    }

    //
    // We have results for the stage.
    //

    // Take note of the debug settings that were last used.
    state.lastDebug = debug ? { data: debug, projectUuid, queryUuid } : null;

    //
    // Determine, whether we are done.
    //

    // This is the actual stage that was delivered, which might be different
    // from the one we requested. E.g. the intermediate stage was requested,
    // but the final stage was delivered, as all augmentations were already available.
    const stageLoaded = results.stage;

    // Check whether we can fetch additional results.
    const hasAdditionalStage = results.hasAdditionalStage;

    // If we are done or can continue with additional results,
    // we need to update the stage's state.

    let done = false;
    let newStageState = false as SearchResultsStageState | false;

    switch ( stageLoaded ) {
        case 'initial':
        case 'intermediate':
            break;
        case 'final':
            done = !hasAdditionalStage;
            newStageState = hasAdditionalStage ? 'loading-additional' : 'done-final';
            break;
        case 'at-once':
            done = true;
            newStageState = 'done-at-once';
            break;
        case 'additional':
            done = true;
            newStageState = 'done-additional';
            break;
    }

    // Make the results available.
    mutateResults( state, queryUuid, projectUuid, results, stageLoaded, newStageState );

    // Deliver results to the user.
    switch ( stageLoaded ) {
        case 'initial':
            deliverInitialResults( state, queryUuid );
            break;
        case 'intermediate':
            deliverIntermediateResults( state, queryUuid );
            break;
        case 'final':
            deliverFinalResults( state, queryUuid );
            break;
        case 'at-once':
            deliverResultAtOnce( state, queryUuid );
            break;
    }

    // Are we done?
    if ( done ) {
        return;
    }

    //
    // Not done yet.
    //

    // Let's load the next stage.
    let nextStage: ApiSearchResultsStage;

    switch ( stageLoaded ) {
        case 'initial':
            nextStage = 'intermediate';
            break;
        case 'intermediate':
            nextStage = 'final';
            break;
        case 'final':
            if ( !hasAdditionalStage ) {
                throw new Error( 'Expected additional results to be available.' );
            }
            nextStage = 'additional';
            break;
        default:
            throw new Error( 'No next stage available for stage ' + stageLoaded + '.' );
    }

    // Wait a bit and then trigger the next stage.
    window.setTimeout( () => {
        fetchResultsStage( state, queryUuid, projectUuid, debug, nextStage );
    }, 1000 );
}

function mutateResults(
    state: State,
    queryUuid: string,
    projectUuid: string | null,
    resultsReturn: FetchApiSearchResultsReturn,
    stage: ApiSearchResultsStage,
    stageState: SearchResultsStageState | false,
) {
    const {
        results,
        aggregations,
        total,
        hitCount,
        availableResultsCount,
        availableCompaniesCount,
        augmentations,
    } = resultsReturn;

    // Sanity check
    if ( availableResultsCount !== total ) {
        // hitCount reflects the total number of results.
        // total reflects the number of results that are available.
        // This could be improved.
        throw new Error( 'Unexpected state: availableResults and total do not match.' );
    }

    // Add results to query stage ...
    const currentResults = state.results[queryUuid] ?? {};
    const updatedResults = { ...currentResults, [stage]: results };

    // ... and add meta information to query stage.
    const currentMetas = state.metas[queryUuid] ?? {};
    const updatedMetas = {
        ...currentMetas,
        [stage]: {
            totalResults: hitCount,
            availableResults: availableResultsCount,
            availableCompanies: availableCompaniesCount,
            aggregations,
            augmentations,
        },
    };

    Vue.set( state.results, queryUuid, updatedResults );
    Vue.set( state.metas, queryUuid, updatedMetas );

    if ( stageState ) {
        Vue.set( state.stagesState, queryUuid, stageState );
    }
}

function resetResults( state: State, queryUuid: string ): void {
    if ( state.results[queryUuid] ) {
        Vue.delete( state.stagesState, queryUuid );
        Vue.delete( state.results, queryUuid );
        Vue.delete( state.deliveredResults, queryUuid );
        Vue.delete( state.metas, queryUuid );
    }
}

function limitStoredQueries( state: State, projectUuid: string ) {
    // Check whether limit is reached.
    if ( ( state.queries[projectUuid]?.length || 0 ) < MAX_STORED_QUERIES ) {
        return;
    }

    // Remove the oldest query and its results.
    const oldestQueryUuid = state.queries[projectUuid][0].uuid;

    Vue.delete( state.queries, oldestQueryUuid );
    Vue.delete( state.stagesState, oldestQueryUuid );
    Vue.delete( state.results, oldestQueryUuid );
    Vue.delete( state.deliveredResults, oldestQueryUuid );
    Vue.delete( state.metas, oldestQueryUuid );
}

/**
 * We allow for mutating the results with updated project entries, in
 * order not to trigger a server round trip each time a user modifies
 * a project entry.
 */
function patchResults( state: State, projectUuid: string, entry: ApiProjectEntry ) {
    // There aren't any queries for the project whose entries got updated.
    if ( !state.queries[projectUuid] ) {
        return;
    }

    // Get all query uuids related to the given project.
    const queries = state.queries[projectUuid].map( ( { uuid } ) => uuid );

    // Now update each query's results.
    queries.forEach( ( uuid ) => {
        if ( !( uuid in state.results ) ) {
            // No results were fetched for this query.
            return;
        }

        // Update the results for each stage.
        const stages = Object.keys( state.results[uuid] ) as ApiSearchResultsStage[];
        const patchedResults = {} as Record<ApiSearchResultsStage, ApiSearchResult[]>;

        stages.forEach( ( stage ) => {
            patchedResults[stage] = state.results[uuid][stage].map( ( result ) =>
                patchResult( result, entry ),
            );
        } );

        Vue.set( state.results, uuid, patchedResults );

        // Update the delivered results.
        const deliveredResults = state.deliveredResults[uuid] ?? [];
        const patchedDeliveredResults = deliveredResults.map( ( result ) =>
            patchResult( result, entry ),
        );

        Vue.set( state.deliveredResults, uuid, patchedDeliveredResults );
    } );
}

function patchResult( result: ApiSearchResult, entry: ApiProjectEntry ): ApiSearchResult {
    // Check whether the top match or any of the additional matches
    // is the company in question.
    const containsCompany =
        result.topMatch.uuid === entry.uuid ||
        result.additionalMatches.find( ( c ) => c.uuid === entry.uuid );

    if ( !containsCompany ) {
        return result;
    }

    // Add or replace the entry for the company in question.
    const entries = result.projectEntries ?? [];
    const projectEntries = entries.filter( ( { uuid } ) => uuid !== entry.uuid );
    projectEntries.push( entry );

    return { ...result, projectEntries };
}

function deduplicateContextCondition(
    productKeywords: SearchConditionKeyword[],
    contextKeywords: SearchConditionKeyword[],
    newConditions: SearchCondition[],
) {
    const lowercasedProductKeywords = productKeywords.map( ( k ) => k.term.toLowerCase() ) || [];
    const filteredContextKeywords = contextKeywords.filter(
        ( k ) => !lowercasedProductKeywords.includes( k.term.toLowerCase() ),
    );
    const hasFilteredOutContextKeywords = contextKeywords.length !== filteredContextKeywords.length;

    if ( hasFilteredOutContextKeywords ) {
        newConditions = newConditions.filter( ( { type } ) => type !== 'context' );

        newConditions.push( {
            keywords: filteredContextKeywords.slice( 0, maxKeywordsPerCondition ),
            required: 0,
            type: 'context',
        } );
    }

    return newConditions;
}

function deliverInitialResults( state: State, queryUuid: string ) {
    // Show all at once.
    const results = state.results[queryUuid]['initial'] || [];

    Vue.set( state.deliveredResults, queryUuid, results );
    Vue.set( state.deliveredStages, queryUuid, 'initial' );
}

function deliverIntermediateResults( state: State, queryUuid: string, index: number = 0 ) {
    const currentStage = stateToStage[state.stagesState[queryUuid]];

    // Check if we got cancelled meanwhile or another stage has arrived.
    if ( currentStage !== 'intermediate' ) {
        return;
    }

    // We are delivering the stage.
    Vue.set( state.deliveredStages, queryUuid, 'intermediate' );
    Vue.set( state.isDelivering, queryUuid, true );

    // Pull the next result from the bottom of the stage ...
    const results = ( state.results[queryUuid]['intermediate'] || [] ).toReversed();

    // Are we done?
    if ( index >= results.length ) {
        Vue.set( state.isDelivering, queryUuid, false );
        return;
    }

    const next = results[index];
    const nextUuid = next.topMatch.uuid;

    // ... remove duplicates ...
    const deliveredResults = state.deliveredResults[queryUuid] || [];
    const existingIndex = deliveredResults.findIndex( ( { topMatch } ) => topMatch.uuid === nextUuid );

    if ( existingIndex !== -1 ) {
        deliveredResults.splice( existingIndex, 1 );
    }

    // ... and push it to the top of the delivered results.
    deliveredResults.unshift( next );

    Vue.set( state.deliveredResults, queryUuid, deliveredResults );

    // Continue with the next result.
    window.setTimeout( () => {
        deliverIntermediateResults( state, queryUuid, index + 1 );
    }, 350 );
}

function deliverFinalResults( state: State, queryUuid: string, index: number = 0 ) {
    const currentState = state.stagesState[queryUuid] as SearchResultsStageState;

    // Check if we got cancelled meanwhile.
    if ( currentState === 'cancelled' ) {
        return;
    }

    // We are delivering the stage.
    Vue.set( state.deliveredStages, queryUuid, 'final' );
    Vue.set( state.isDelivering, queryUuid, true );

    // Pull the next result from the bottom of the stage's first 5 results ...
    const results = state.results[queryUuid]['final'] || [];
    const firstPageIndex = Math.min( 5, results.length ) - index - 1;

    // Are we done?
    if ( firstPageIndex < 0 ) {
        // After the first page is populated one by one,
        // we can deliver the rest at once and dump the ones
        // that were delivered before.
        Vue.set( state.deliveredResults, queryUuid, results );
        Vue.set( state.isDelivering, queryUuid, false );

        return;
    }

    const next = results[firstPageIndex];
    const nextUuid = next.topMatch.uuid;

    // ... remove duplicates ...
    const deliveredResults = state.deliveredResults[queryUuid] || [];
    const existingIndex = deliveredResults.findIndex( ( { topMatch } ) => topMatch.uuid === nextUuid );

    if ( existingIndex !== -1 ) {
        deliveredResults.splice( existingIndex, 1 );
    }

    // ... and push it to the top of the delivered results.
    deliveredResults.unshift( next );

    Vue.set( state.deliveredResults, queryUuid, deliveredResults );

    // Continue with the next result.
    window.setTimeout( () => {
        deliverFinalResults( state, queryUuid, index + 1 );
    }, 350 );
}

function deliverAdditionalResults( state: State, queryUuid: string ) {
    // Show all at once.
    const results = state.results[queryUuid]['additional'] || [];

    Vue.set( state.deliveredResults, queryUuid, results );
    Vue.set( state.deliveredStages, queryUuid, 'additional' );
}

function deliverResultAtOnce( state: State, queryUuid: string ) {
    // Show all at once.
    const results = state.results[queryUuid]['at-once'] || [];

    Vue.set( state.deliveredResults, queryUuid, results );
    Vue.set( state.deliveredStages, queryUuid, 'at-once' );
}

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useSearchStore, import.meta.hot ) );
}
