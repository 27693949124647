import type { ApiSearchQuery } from '@main/api/resources/search';

/**
 * SearchQuery wraps an api search query and
 * provides methods to access its properties.
 */
export class SearchQuery {
    constructor( private readonly resource: ApiSearchQuery ) {}

    apiResource() {
        return this.resource;
    }

    uuid() {
        return this.resource.uuid;
    }

    createdAt() {
        return this.resource.createdAt;
    }

    mutableProperties() {
        return {
            conditions: this.conditions(),
            filters: this.filters(),
        };
    }

    filters() {
        return this.resource.filters || [];
    }

    customerFilterCompanies() {
        return this.resource.customerFilterCompanies || [];
    }

    conditions() {
        return this.resource.conditions || [];
    }

    productCondition() {
        return this.conditions().find( ( c ) => c.type === 'product' );
    }

    contextCondition() {
        return this.conditions().find( ( c ) => c.type === 'context' );
    }

    /**
     * This condition is special in the sense that it is the only one
     * that can repeat keywords from other conditions.
     */
    requiredCondition() {
        return this.conditions().find( ( c ) => c.type === 'keyword-filter' );
    }

    requiredKeywords() {
        return this.requiredCondition()?.keywords ?? [];
    }

    productKeywords() {
        return this.productCondition()?.keywords ?? [];
    }

    contextKeywords() {
        return this.contextCondition()?.keywords ?? [];
    }

    /**
     * All unique terms excluding the ones that are repeated in the
     * required keyword-filter condition.
     */
    allTerms() {
        const keywords = this.conditions()
            .filter( ( c ) => c.type !== 'keyword-filter' )
            .sort( ( a ) => ( a.type === 'product' ? -1 : 1 ) )
            .map( ( c ) => c.keywords.map( ( k ) => k.term ) )
            .flat();

        // Let's check for keywords in the keyword-filter condition
        // that were added by the user.
        const keywordFilter = this.requiredCondition();

        if ( !keywordFilter ) {
            return keywords;
        }

        const lowerKeywords = keywords.map( ( k ) => k.toLowerCase() );
        const requiredKeywords = keywordFilter.keywords.map( ( k ) => k.term );

        requiredKeywords.forEach( ( k ) => {
            const lowerKeyword = k.toLowerCase();
            if ( !lowerKeywords.includes( lowerKeyword ) ) {
                keywords.push( k );
            }
        } );

        return keywords;
    }

    hasTerm( term: string ) {
        return this.allTerms()
            .map( ( k ) => k.toLowerCase() )
            .includes( term.toLowerCase() );
    }

    allRequiredTerms() {
        return this.requiredKeywords().map( ( { term } ) => term ) ?? [];
    }

    /**
     * Required terms added by the user in the required keywords section.
     */
    additionalRequiredTerms() {
        const keywordFilter = this.requiredCondition();

        if ( !keywordFilter ) {
            return [];
        }

        const standardKeywords = this.conditions()
            .filter( ( { type } ) => type !== 'keyword-filter' )
            .map( ( c ) => c.keywords.map( ( { term } ) => term ) )
            .flat();

        const lowerKeywords = standardKeywords.map( ( k ) => k.toLowerCase() );

        return keywordFilter.keywords
            .map( ( { term } ) => term )
            .filter( ( k ) => !lowerKeywords.includes( k.toLowerCase() ) );
    }

    productTerms() {
        return this.productKeywords().map( ( { term } ) => term ) ?? [];
    }

    contextTerms() {
        return this.contextKeywords().map( ( { term } ) => term ) ?? [];
    }

    joinedRequiredTerms() {
        return this.allRequiredTerms().join( ', ' ) ?? '';
    }
    joinedProductTerms() {
        return this.productTerms().join( ', ' ) ?? '';
    }
    joinedContextTerms() {
        return this.contextTerms().join( ', ' ) ?? '';
    }
}
