import type { ApiIndexHistory, ApiIndexHistoryItem } from '@main/api/resources/debug';

export class IndexHistory {
    readonly resource: ApiIndexHistory;

    constructor( resource: ApiIndexHistory ) {
        this.resource = ( resource?.map( ( item: ApiIndexHistoryItem ) => item ) ?? [] ).sort(
            ( a: ApiIndexHistoryItem, b: ApiIndexHistoryItem ) => ( a.date < b.date ? -1 : 1 ),
        );
    }

    nicknameFromUuid( uuid: string ) {
        return this.resource.find( ( item ) => item.lastUuid >= uuid )?.nickname;
    }
}
