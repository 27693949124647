/**
 * API limits across all endpoints.
 */

// region Communication

export const maxNameLength = 255;
export const maxPositionLength = 255;
export const maxPhoneLength = 255;
export const maxEmailAddressLength = 255;
export const maxEmailSubjectLength = 500;
export const maxCommentLength = 5000;

// endregion

// region RFX

export const maxRfxTitleLength = 255;
export const maxRfxDescriptionLength = 5000;
export const maxRfxAnswerLength = 6000;

// endregion

// region Files

export const maxDocumentSizeBytes = 4194304;

export const acceptedDocumentMimeType = [
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',

    'image/jpg',
    'image/jpeg',
    'image/png',

    'application/pdf',
    'application/acad',
    'application/zip',

    'application/epub+zip',
    'text/html',
    'text/csv',
    'text/rtf',
    'text/plain',
];

export const acceptedImageMimeTypes = ['image/jpg', 'image/jpeg', 'image/png'];

// endregion

// region queries
export const maxKeywordsPerCondition = 15;

export const maxKeywordSuggestions = 20;

// endregion

// region string matching

export const minSearchTermLength = 2;

export const minWebsiteLength = 3;

// endregion
